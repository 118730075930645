import '../maybe-await-google-maps.js';

// Atlas is the codename for PredictWind's WebGL code
// IMPORTANT: configuration is duplicated in atlas.pwMap.js, please keep in sync.
var pwMapDefaultAPI = (window.location.search.indexOf('useGoogle') != -1) ? 'google' :
                      (window.location.search.indexOf('useLeaflet') != -1) ? 'leaflet' :
                      (window.location.search.indexOf('useAtlas') != -1) ? 'atlas' :
                      (window.pwMapDefaultAPI || 'leaflet');
var pwMapDefaultTMS = (window.location.search.indexOf('useMapBox') != -1) ? 'mapbox' :
                      (window.location.search.indexOf('useCloudMade') != -1) ? 'cloudmade' :
                      (window.pwMapDefaultTMS || 'mapbox');
var pwMapDefaultMap = (window.location.search.indexOf('useStreetMap') != -1) ? 'street' :
                      (window.location.search.indexOf('useTerrainMap') != -1) ? 'terrain' :
                      (window.location.search.indexOf('useSatellite') != -1) ? 'satellite' :
                      (window.pwMapDefaultMap || 'terrain');
var pwMapIsSmartPhone = (window.location.host.indexOf('iphone') != -1) ||
                        /sys=(ios|andr)/i.test(window.navigator.userAgent) ||
                        (window.location.search.indexOf('isSmartphone') != -1);

var hasGoogleMaps = !!(window.google && window.google.maps && window.google.maps.OverlayView);

// sometimes google maps doesn't load, so try a reload
if (pwMapDefaultAPI == 'google' && !hasGoogleMaps) {
    if (location.search == '') {
        location.search = '?reload';
    } else if (location.search.indexOf('reload') == -1) {
        location.search = location.search + '&reload';
    }
}

export function getSetting(key, defaultValue) {
    return window.app ? window.app.getSetting(key, defaultValue) : defaultValue;
}

export function setSetting(key, value) {
    if (window.app) {
        window.app.setSetting(key, value);
    }
}

export const mapBoxToken = window.MAPBOX_TOKEN || 'pk.eyJ1IjoicHJlZGljdHdpbmRtYXBib3giLCJhIjoiMTdyb2FVZyJ9.wgfg9nA1bTCDGiU8r4O34w';

// blue-greyscale map for leaflet, etc.
const
    mapBoxTileUrl = 'https://api.mapbox.com/styles/v1/predictwindmapbox/cjut12ayq50wd1gqw2tg6lngq/tiles/{tileSize}/{z}/{x}/{y}{scale}?access_token={token}',
    mapBoxTileSize = 256,
    mapBoxScale = '';

// colour coded map for Atlas, requires shaders.
let mapBoxAtlasTileUrl, mapBoxAtlasTileSize, mapBoxAtlasVectorTileUrl, mapBoxAtlasScale;
if (window.Atlas) {
    if (window.Atlas.USE_TRANSPARENT_MAP_TILES) {
        mapBoxAtlasTileUrl = 'https://api.mapbox.com/styles/v1/predictwindmapbox/cju7v1xim754f1fp672k0q6dy/tiles/{tileSize}/{z}/{x}/{y}{scale}?access_token={token}';
        mapBoxAtlasTileSize = window.Atlas.DEFAULT_TILE_SIZE;
        mapBoxAtlasVectorTileUrl = 'https://api.mapbox.com/v4/mapbox.mapbox-streets-v8,mapbox.d4advw8k,mapbox.mapbox-terrain-v2/{z}/{x}/{y}.vector.pbf?access_token={token}';
    } else {
        mapBoxAtlasTileUrl = 'https://api.tiles.mapbox.com/v4/predictwindmapbox.ho81a30j/{z}/{x}/{y}{scale}.png?access_token={token}'; // terrain
        mapBoxAtlasTileSize = 256;
    }
    mapBoxAtlasScale = window.Atlas.PIXEL_RATIO === 2 ? '@2x' : '';
}

// get tile urls
export function getDefaultTileLayerUrl() {
    if (pwMapDefaultTMS === 'cloudmade') {
        return cloudMadeTileUrl;
    }
    if (pwMapDefaultTMS === 'mapbox') {
        var tileUrl, tileSize, tileScale;
        if (pwMapDefaultAPI === 'atlas') {
            tileUrl = mapBoxAtlasTileUrl;
            tileSize = mapBoxAtlasTileSize;
            tileScale = mapBoxAtlasScale;
        } else {
            tileUrl = mapBoxTileUrl;
            tileSize = mapBoxTileSize;
            tileScale = mapBoxScale;
        }
        tileUrl = tileUrl.replace('{scale}', getSetting('Client_DeviceScaleSuffix') || tileScale);
        tileUrl = tileUrl.replace('{tileSize}', tileSize.toString());
        tileUrl = tileUrl.replace('{token}', getSetting('Maps_TileLayerToken') || mapBoxToken);
        return tileUrl;
    }
    return null;
}

export function getDefaultVectorTileLayerUrl() {
    if (pwMapDefaultTMS === 'mapbox') {
        var tileUrl = mapBoxAtlasVectorTileUrl;
        tileUrl = tileUrl.replace('{token}', getSetting('Maps_TileLayerToken') || mapBoxToken);
        return tileUrl;
    }
    return null;
}


// constants
export const
    MAPTYPE_TERRAIN = "terrain", // google.maps.MapTypeId.TERRAIN
    SEARCH_ZOOM_DEFAULT = 6;
const
    MAPTYPE_STREET = "roadmap", // google.maps.MapTypeId.ROADMAP
    MAX_ZINDEX = 1000000;

if (window.app) {
    window.app.addPageListener(function(){
        window.app.setSetting('Maps_TileLayerUrl', getDefaultTileLayerUrl());
    });
}

let legalInformation = null;
if (pwMapDefaultTMS === 'cloudmade') {
    legalInformation = 'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
                             'Imagery &copy; <a href="http://cloudmade.com">CloudMade</a>';
}

const hasSpriteClass = {};
export const zLevels = {
    // interactive
    routeEndpoint: 4200,
    routeWaypoint: 4100,
    // air
    windSample: 3200,
    windLabel: 3100,
    // surface
    routePosition: 2400,
    routeSample: 2300,
    routePath: 2200,
    routeLine: 2100,
    // ground
    customLocation: 1800,
    locationMarker: 1700,
    locationLabel: 1600,
    zoomRegion: 1500,
    hiresRegion: 1400,
    tidalRegion: 1300,
    raceBoundary: 1200,

    weatherUpper: 1100,
    surfaceLevel: 1000,
    weatherLower: 900,
};
const dynamicStyle = document.createElement("style");
dynamicStyle.appendChild(document.createTextNode(""));
document.head.appendChild(dynamicStyle);

// utils
const
    latitude_regex_strings = [
        // Degrees, minutes, decimal seconds:
        "([-+]?)\\s*([NS]?)\\s*([0]*[1-8]?\\d)\\s*(?:([NS])|[,\\s]+)\\s*([0]*[1-5]?\\d)\\s*(?:'|[,\\s])\\s*([0]*[1-5]?\\d(?:\\.\\d+)?)\\s*\"?\\s*([NS]?)",
        // Degrees, decimal minutes:
        "([-+]?)\\s*([NS]?)\\s*([0]*[1-8]?\\d)\\s*(?:([NS])|[,\\s]+)\\s*([0]*[1-5]?\\d(?:\\.\\d+)?)\\s*'?\\s*([NS]?)",
        // Decimal degrees:
        "([-+]?)\\s*([NS]?)\\s*([0]*90|(?:[0]*[1-8]?\\d(?:\\.\\d+)?))(?:\\s+|([NS]?))"
    ],
    latitude_regex_group_names = [
        ["ysign", "yd1", "yd", "yd2", "ym", "ys", "yd3"],
        ["ysign", "yd1", "yd", "yd2", "ym", "yd3"],
        ["ysign", "yd1", "yd", "yd2"]
    ],
    longitude_regex_strings = [
        // Degrees, minutes, decimal seconds:
        "([-+]?)\\s*([EW]?)\\s*([0]*1[0-8]\\d|[0]*\\d{1,2})\\s*(?:([EW])|[,\\s]+)\\s*([0]*[1-5]?\\d)\\s*(?:'|[,\\s])\\s*([0]*[1-5]?\\d(?:\\.\\d+)?)\\s*\"?\\s*([EW]?)",
        // Degrees, decimal minutes:
        "([-+]?)\\s*([EW]?)\\s*([0]*1[0-8]\\d|[0]*\\d{1,2})\\s*(?:([EW])|[,\\s]+)\\s*([0]*[1-5]?\\d(?:\\.\\d+)?)\\s*'?\\s*([EW]?)",
        // Decimal degrees:
        "([-+]?)\\s*([EW]?)\\s*([0]*180|(?:(?:[0]*1[0-7]\\d|[0]*\\d{1,2})(?:\\.\\d+)?))\\s*([EW]?)"
    ], longitude_regex_group_names = [
        ["xsign", "xd1", "xd", "xd2", "xm", "xs", "xd3"],
        ["xsign", "xd1", "xd", "xd2", "xm", "xd3"],
        ["xsign", "xd1", "xd", "xd2"]
    ];

export const utils = {
    spriteClassName: function (url, u, v) {
        var name = url.replace(/[ `~!@#$%^&*()_=+[\]{}\\|:;"',.<>\/?]/g, '');
        return 'pwSprite-' + name + '-u' + (u || 0) + 'v' + (v || 0);
    },
    getStyle: function (selector) {
        var sheet = dynamicStyle.sheet;
        var rules = sheet.cssRules ? sheet.cssRules : sheet.rules;
        if (rules) {
            for (var i = 0; i < rules.length; i++) {
                if (rules[i].selectorText.toLowerCase() == selector.toLowerCase()) {
                    return rules[i];
                }
            }
        }
        return null;
    },
    addStyle: function (selector, cssText, index) {
        if (utils.getStyle(selector)) {
            return;
        }
        var sheet = dynamicStyle.sheet;
        var rules = sheet.cssRules ? sheet.cssRules : sheet.rules;
        if (index === undefined) {
            index = rules.length;
        }
        if (sheet.addRule) {
            sheet.addRule(selector, cssText, index);
        } else if (sheet.insertRule) {
            sheet.insertRule(selector + '{' + cssText + '}', index);
        }
    },
    xFromLon: function (lon, z, keepFractional, keepUnmodulated) {
        if (!keepUnmodulated) {
            lon = utils.modulateLon(lon);
        }
        var x = (lon + 180) / 360 * (1 << z);
        return keepFractional ? x : Math.floor(x);
    },
    yFromLat: function (lat, z, keepFractional) {
        lat = Math.max(-85.05112878, Math.min(lat, 85.05112878));
        var y = (1 - Math.log(Math.tan(lat * Math.PI / 180) + 1 / Math.cos(lat * Math.PI / 180)) / Math.PI) / 2 * (1 << z);
        return Math.max(0, Math.min(keepFractional ? y : Math.floor(y), 1 << z));
    },
    lonFromX: function (x, z, keepUnmodulated) {
        var lon = x / (1 << z) * 360 - 180;
        return keepUnmodulated ? lon : utils.modulateLon(lon);
    },
    latFromY: function (y, z) {
        y = Math.max(0, Math.min(y, 1 << z));
        var n = Math.PI - 2 * Math.PI * y / (1 << z);
        return 180 / Math.PI * Math.atan(0.5 * (Math.exp(n) - Math.exp(-n)));
    },
    modulate: function (p) {
        return {lat: p.lat, lon: utils.modulateLon(p.lon)};
    },
    modulateLon: function (lon) {
        return ((lon % 360 + 540) % 360) - 180;
    },
    interpolate: function (p, q, t) {
        return {
            lat: p.lat + (q.lat - p.lat) * t,
            lon: utils.interpolateLon(p.lon, q.lon, t)
        };
    },
    interpolateLon: function (lon1, lon2, t) {
        lon1 = utils.modulateLon(lon1);
        lon2 = utils.modulateLon(lon2);
        if (lon1 < lon2 - 180) {
            lon1 += 360;
        } else if (lon2 < lon1 - 180) {
            lon2 += 360;
        }
        return utils.modulateLon(lon1 + (lon2 - lon1) * t);
    },
    _unitVectorFromCoords: function(p) {
        const R = Math.PI / 180;
        const pu = p.lon * R;
        const pv = p.lat * R;
        const cos_pv = Math.cos(pv);
        return { x:cos_pv * Math.cos(pu), y:cos_pv * Math.sin(pu), z:Math.sin(pv) };
    },
    _unitVectorMidpoint: function(v0, v1) {
        let v = { x:(v0.x + v1.x) / 2, y:(v0.y + v1.y) / 2, z:(v0.z + v1.z) / 2 };
        let h = Math.sqrt(v.x * v.x + v.y * v.y + v.z * v.z);
        v.x /= h;
        v.y /= h;
        v.z /= h;
        return v;
    },
    _coordsFromUnitVector: function(v) {
        const R = Math.PI / 180;
        const h = Math.sqrt(v.x * v.x + v.y * v.y);
        const lat = Math.atan2(z, h) / R;
        const lon = Math.atan2(y, x) / R;
        return {lat:lat, lon:lon};
    },
    subdivideCoords: function(p0, p1, passes) {
        const ps = [p0, p1];
        const vs = [utils._unitVectorFromCoords(p0), utils._unitVectorFromCoords(p1)];
        for (let j = 0; j < passes; j++) {
            for (let i = 0; i < vs.length - 1; i += 2) {
                const v = utils._unitVectorMidpoint(vs[i], vs[i + 1]);
                const p = utils._coordsFromUnitVector(v);
                vs.splice(i, 0, v);
                ps.splice(i, 0, p);
            }
        }
        return cs;
    },
    midpoint: function (p, q, t) {
        // Compute geodesic midpoint of two given points. Not the same as interpolate(p, q, 0.5)
        // which does linear interpolation EXCEPT for the handling of wrapping in longitude.
        // convert to radians
        const R = Math.PI / 180;
        const pu = p.lon * R, pv = p.lat * R;
        const qu = q.lon * R, qv = q.lat * R;
        // convert to vectors
        const cos_pv = Math.cos(pv);
        const cos_qv = Math.cos(qv);
        const px = cos_pv * Math.cos(pu);
        const py = cos_pv * Math.sin(pu);
        const pz = Math.sin(pv);
        const qx = cos_qv * Math.cos(qu);
        const qy = cos_qv * Math.sin(qu);
        const qz = Math.sin(qv);
        let x, y, z;
        if (t === 0.5 || t === undefined) {
            x = (px + qx) / 2;
            y = (py + qy) / 2;
            z = (pz + qz) / 2;
        } else {
            // calculate new theta
            const dot = Math.max(-1, Math.min(px * qx + py * qy + pz * qz, 1)); // [-1, +1]
            const theta = Math.acos(dot) * t;
            // orthonormal basis (p, r)
            let rx = qx - px * dot;
            let ry = qy - py * dot;
            let rz = qz - pz * dot;
            const rh = Math.sqrt(rx * rx + ry * ry + rz * rz);
            if (rh === 0) {
                return p;
            }
            rx /= rh;
            ry /= rh;
            rz /= rh;
            // result from basis and theta
            const cos_theta = Math.cos(theta);
            const sin_theta = Math.sin(theta);
            x = px * cos_theta + rx * sin_theta;
            y = py * cos_theta + ry * sin_theta;
            z = pz * cos_theta + rz * sin_theta;
        }
        // convert to lat,lon
        const h = Math.sqrt(x * x + y * y);
        const lat = Math.atan2(z, h) / R;
        const lon = Math.atan2(y, x) / R;
        return {lat:lat, lon:lon};
    },
    midpointAtLongitude: function (p, q, split_longitude) {
        // convert to radians
        const R = Math.PI / 180;
        const pu = p.lon * R, pv = p.lat * R;
        const qu = q.lon * R, qv = q.lat * R;
        // convert to vectors
        const cos_pv = Math.cos(pv);
        const cos_qv = Math.cos(qv);
        const px = cos_pv * Math.cos(pu);
        const py = cos_pv * Math.sin(pu);
        const pz = Math.sin(pv);
        const qx = cos_qv * Math.cos(qu);
        const qy = cos_qv * Math.sin(qu);
        const qz = Math.sin(qv);
        // longitudinal plane normal, based at origin so points on plane, P dot N = 0
        const nu = (split_longitude + 90) * R;
        const nx = Math.cos(nu);
        const ny = Math.sin(nu);
        const nz = 0;
        // line delta from p to q. l and d are unnormalised
        const lx = qx - px;
        const ly = qy - py;
        const lz = qz - pz;
        const d = -(px * nx + py * ny + pz * nz) / (lx * nx + ly * ny + lz * nz);
        // unnormalised point at lon
        const x = px + lx * d;
        const y = py + ly * d;
        const z = pz + lz * d;
        // convert to lat,lon
        const h = Math.sqrt(x * x + y * y);
        const lat = Math.atan2(z, h) / R;
        const lon = Math.atan2(y, x) / R; // lon == split_longitude (with possible modulus)
        return {lat:lat, lon:lon};
    },
    lonDist: function (lon1, lon2) {
        var dist = Math.abs(lon1 - lon2);
        if (dist > 180) {
            dist = 360 - dist;
        }
        return dist;
    },
    boundsCentre: function (b) {
        if (!b) return null;
        return utils.interpolate({lat: b.s, lon: b.w}, {lat: b.n, lon: b.e}, 0.5);
    },
    boundsSpan: function (b) {
        if (!b) return null;
        return {lat: (b.n - b.s), lon: (b.e - b.w) + (b.e < b.w ? 360 : 0)};
    },
    boundsContains: function (b, p) {
        if (!b) return true;
        p = utils.modulate(p);
        return (p.lat >= b.s) && (p.lat <= b.n) && (b.e >= b.w ? (p.lon >= b.w && p.lon <= b.e) : (p.lon >= b.w || p.lon <= b.e));
    },
    boundsExtend: function (b, p) {
        p = utils.modulate(p);
        if (!b) {
            return {s: p.lat, n: p.lat, w: p.lon, e: p.lon};
        }
        // extend in latitude
        var r = {
            s: Math.max(-90, Math.min(b.s, p.lat)),
            n: Math.min(90, Math.max(b.n, p.lat)),
            w: b.w,
            e: b.e
        };
        if (utils.boundsContains(r, p)) {
            return r;
        }
        // extend in longitude
        if (b.e == b.w) {
            if ((Math.abs(p.lon - b.w) < 180) == (p.lon > b.w)) {
                r.e = p.lon;
            } else {
                r.w = p.lon;
            }
        } else {
            var wDist = utils.lonDist(p.lon, b.w);
            var eDist = utils.lonDist(p.lon, b.e);
            if (wDist < eDist) {
                r.w = p.lon;
            } else {
                r.e = p.lon;
            }
        }
        return r;
    },
    boundsIntersect: function (b, c) {
        if (b.n < c.s || b.s > c.n) { return false; }
        var be = utils.modulateLon(b.e),
            bw = utils.modulateLon(b.w),
            ce = utils.modulateLon(c.e),
            cw = utils.modulateLon(c.w);
        if (bw > be) {
            // b wraps around +/- 180"
            if (cw > ce) {
                // so does c... they meet there!
                return true;
            }
            // Bounds intersect if c does not lie between be and bw
            return (be > cw || ce > bw);
        }
        if (cw > ce) {
            // only c wraps around.
            // Bounds intersect if b does not lie between ce and cw
            return (ce > bw || be > cw);
        }
        // no wrapping!
        return !(be < cw || bw > ce);
    },
    boundsUnion: function (b, c) {
        if (!b || !c) return b || c;
        var r = {
            s: Math.max(-90, Math.min(b.s, c.s)),
            n: Math.min(90, Math.max(b.n, c.n))
        };
        // unroll bounds, west <= east
        var bw = b.w;
        var cw = c.w;
        var be = (b.w <= b.e) ? b.e : b.e + 360;
        var ce = (c.w <= c.e) ? c.e : c.e + 360;
        // union1, westmost first
        var w1 = Math.min(bw, cw);
        var e1 = Math.max(be, ce);
        // union2, westmost last by adding 360
        var w2, e2;
        if (bw < cw) {
            w2 = Math.min(bw + 360, cw);
            e2 = Math.max(be + 360, ce);
        } else {
            w2 = Math.min(bw, cw + 360);
            e2 = Math.max(be, ce + 360);
        }
        // choose union with minimum span
        if (e1 - w1 <= e2 - w2) {
            r.w = utils.modulateLon(w1);
            r.e = utils.modulateLon(e1);
        } else {
            r.w = utils.modulateLon(w2);
            r.e = utils.modulateLon(e2);
        }
        return r;
    },
    boundsForCorners: function (p, q) {
        var positiveLat = p.lat < q.lat;
        var positiveLon = utils.modulateLon(q.lon - p.lon) >= 0;
        return {
            s: (positiveLat ? p.lat : q.lat),
            n: (positiveLat ? q.lat : p.lat),
            w: (positiveLon ? p.lon : q.lon),
            e: (positiveLon ? q.lon : p.lon)
        };
    },
    formatCoords: function (lat, lon, options) {
        if (typeof lat !== "number") {
            options = lon;
            lon = lat.lon;
            lat = lat.lat;
        }
        return utils.formatLatitude(lat, options) + ' ' + utils.formatLongitude(lon, options);
    },
    formatLatitude: function (lat, options) {
        function d60(value, dp) {
            value = value.toFixed(dp || 0);
            if (value.length < 2 || value.indexOf('.') == 1) {
                return '0' + value;
            }
            return value;
        }

        var dir = lat < 0 ? 's' : 'n';
        lat = Math.abs(lat);
        var degreeSymbol = (options && options.degreeSymbol) ? '\xB0' : '';
        var minutes = (lat * 60 % 60);
        lat -= minutes / 60;
        var degrees = Math.round(lat);
        return degrees + degreeSymbol + ' ' + d60(minutes, 3) + dir;
    },
    formatLongitude: function (lon, options) {
        function d60(value, dp) {
            value = value.toFixed(dp || 0);
            if (value.length < 2 || value.indexOf('.') == 1) {
                return '0' + value;
            }
            return value;
        }

        lon = utils.modulateLon(lon);
        var dir = lon < 0 ? 'w' : 'e';
        lon = Math.abs(lon);
        var
            degreeSymbol = (options && options.degreeSymbol) ? '\xB0' : '',
            minutes = (lon * 60 % 60);
        lon -= minutes / 60;
        var degrees = Math.round(lon);
        return degrees + degreeSymbol + ' ' + d60(minutes, 3) + dir;
    },
    parseLat: function (text) {
        text = text
            .toUpperCase()
            .replace('°', ' '); // replace degree symbol
        var pys = latitude_regex_strings,
            pyn = latitude_regex_group_names,
            params = {};
        for (var j = 0; j < pys.length; j++) {
            var regex = new RegExp('^\\s*' + pys[j] + '\\s*$'),
                m = regex.exec(text);
            if (m) {
                for (var y = 0, n = 1; y < pyn[j].length; y++, n++) {
                    params[pyn[j][y]] = m[n];
                }
                var lat = (
                    +(params['yd'] || 0) // lat degrees
                    + (params['ym'] || 0) / 60 // lat minutes
                    + (params['ys'] || 0) / 3600 // lat seconds
                );
                // direction modifiers: yd1,yd2,yd3,ysign
                if ((params['ysign'] === '-') !== (params['yd1'] === 'S' || params['yd2'] === 'S' || params['yd3'] === 'S')) {
                    lat = -lat;
                }
                return lat;
            }
        }
    },
    parseLon: function (text) {
        text = text
            .toUpperCase()
            .replace('°', ' '); // replace degree symbol
        var pxs = longitude_regex_strings,
            pxn = longitude_regex_group_names,
            params = {};
        for (var i = 0; i < pxs.length; i++) {
            var regex = new RegExp('^\\s*' + pxs[i] + '\\s*$'),
                m = regex.exec(text);
            if (m) {
                for (var x = 0, n = 1; x < pxn[i].length; x++, n++) {
                    params[pxn[i][x]] = m[n];
                }
                var lon = (
                    +(params['xd'] || 0) // lon degrees
                    + (params['xm'] || 0) / 60 // lon minutes
                    + (params['xs'] || 0) / 3600 // lon seconds
                );
                // direction modifiers: xd1,xd2,xd3,xsign
                if ((params['xsign'] === '-') !== (params['xd1'] === 'W' || params['xd2'] === 'W' || params['xd3'] === 'W')) {
                    lon = -lon;
                }
                // Longitudes like 181 degrees can get this far, reject those by returning undefined
                if (Math.abs(lon) > 180) {
                    return;
                }
                return lon;
            }
        }
    },
    parseCoords: function (text) {
        text = text
            .toUpperCase()
            .replace('°', ' '); // replace degree symbol
        var pys = latitude_regex_strings,
            pxs = longitude_regex_strings,
            pyn = latitude_regex_group_names,
            pxn = longitude_regex_group_names,
            params = {};
        var m = null;
        for (var j = 0; j < pys.length && !m; j++) {
            for (var i = 0; i < pxs.length && !m; i++) {
                var regex = new RegExp('^\\s*' + pys[j] + '\\s+' + pxs[i] + '\\s*$');
                m = regex.exec(text);
                if (m) {
                    var n = 1;
                    for (var y = 0; y < pyn[j].length; y++, n++) {
                        if (m[n] !== '') {
                            params[pyn[j][y]] = m[n];
                        }
                    }
                    for (var x = 0; x < pxn[i].length; x++, n++) {
                        if (m[n] !== '') {
                            params[pxn[i][x]] = m[n];
                        }
                    }
                }
            }
        }
        if (m) {
            var lat = 0;
            var lon = 0;
            if (params['yd'] !== undefined) { // lat degrees
                lat += params['yd'] * 1;
            }
            if (params['ym'] !== undefined) { // lat minutes
                lat += params['ym'] / 60;
            }
            if (params['ys'] !== undefined) { // lat seconds
                lat += params['ys'] / 3600;
            }
            if (params['xd'] !== undefined) { // lon degrees
                lon += params['xd'] * 1;
            }
            if (params['xm'] !== undefined) { // lon minutes
                lon += params['xm'] / 60;
            }
            if (params['xs'] !== undefined) { // lon seconds
                lon += params['xs'] / 3600;
            }
            // direction modifiers: xd1,xd2,xd3,xsign and yd1,yd2,yd3,ysign
            if ((params['ysign'] === '-') !== (params['yd1'] === 'S' || params['yd2'] === 'S' || params['yd3'] === 'S')) {
                lat = -lat;
            }
            if ((params['xsign'] === '-') !== (params['xd1'] === 'W' || params['xd2'] === 'W' || params['xd3'] === 'W')) {
                lon = -lon;
            }
            // Longitudes like 181 or 180.3 degrees can get this far, reject those by returning null
            if (Math.abs(lon) > 180) {
                return null;
            }
            return {lat: lat, lon: lon};
        }
        return null;
    },
    haversineDistance: function (p, q) {
        // NOTE: returns distance in NAUTICAL MILES (python version uses kilometres)
        // https://stackoverflow.com/a/27943
        function deg2rad(deg) {
            return deg * (Math.PI / 180);
        }

        var R = 3443.92; // radius of the earth in nautical miles
        var dLat = deg2rad(q.lat - p.lat);
        var dLon = deg2rad(q.lon - p.lon);
        var a = Math.sin(dLat/2) ** 2 +
                Math.cos(deg2rad(p.lat)) * Math.cos(deg2rad(q.lat)) * Math.sin(dLon/2) ** 2;
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c; // distance in NM
        return d;
    },
    haversineDistancePolyline: function (points) {
        // NOTE: returns distance in NAUTICAL MILES
        // FIXME: only routing.js depends on this, should it be here?
        var p = points[0];
        var q;
        var total = 0;
        for (var i = 1; i < points.length; i++) {
            q = points[i];
            total += utils.haversineDistance(p, q);
            p = q;
        }
        return total;
    }
};

// types
export const Google = {};
Google.Map = GoogleMap;
Google.Polygon = GooglePolygon;
Google.Polyline = GooglePolyline;
Google.Rectangle = GoogleRectangle;
Google.Circle = GoogleCircle;
Google.CircleMarker = GoogleCircleMarker;
Google.Marker = GoogleMarker;
Google.Icon = GoogleIcon;
Google.Element = GoogleElement;
Google.Geocoder = GoogleGeocoder;
Google.LayerGroup = GoogleLayerGroup;
export const Leaflet = {};
Leaflet.Map = LeafletMap;
Leaflet.Polygon = LeafletPolygon;
Leaflet.Polyline = LeafletPolyline;
Leaflet.Rectangle = LeafletRectangle;
Leaflet.Circle = LeafletCircle;
Leaflet.CircleMarker = LeafletCircleMarker;
Leaflet.Marker = LeafletMarker;
Leaflet.Icon = LeafletIcon;
Leaflet.Element = LeafletElement;
Leaflet.Geocoder = MapboxAjaxGeocoder;
Leaflet.LayerGroup = LeafletLayerGroup;
export const Atlas = {};
Atlas.Map = AtlasMap;
Atlas.Polygon = AtlasPolygon;
Atlas.Polyline = AtlasPolyline;
Atlas.Rectangle = AtlasRectangle;
Atlas.Circle = AtlasCircle;
Atlas.CircleMarker = AtlasCircleMarker;
Atlas.Marker = AtlasMarker;
Atlas.Icon = AtlasIcon;
Atlas.Element = AtlasElement;
Atlas.Geocoder = MapboxAjaxGeocoder;
Atlas.LayerGroup = AtlasLayerGroup;

export let Map, Polygon, Polyline, Rectangle, Circle, CircleMarker, Marker, Icon, Element, Geocoder, LayerGroup;
if (pwMapDefaultAPI === 'google') {
    Map = GoogleMap;
    Polygon = GooglePolygon;
    Polyline = GooglePolyline;
    Rectangle = GoogleRectangle;
    Circle = GoogleCircle;
    CircleMarker = GoogleCircleMarker;
    Marker = GoogleMarker;
    Icon = GoogleIcon;
    Element = GoogleElement;
    Geocoder = GoogleGeocoder;
    LayerGroup = GoogleLayerGroup;
}
if (pwMapDefaultAPI === 'leaflet') {
    Map = LeafletMap;
    Polygon = LeafletPolygon;
    Polyline = LeafletPolyline;
    Rectangle = LeafletRectangle;
    Circle = LeafletCircle;
    CircleMarker = LeafletCircleMarker;
    Marker = LeafletMarker;
    Icon = LeafletIcon;
    Element = LeafletElement;
    Geocoder = MapboxAjaxGeocoder;
    LayerGroup = LeafletLayerGroup;
}
if (pwMapDefaultAPI === 'atlas') {
    Map = AtlasMap;
    Polygon = AtlasPolygon;
    Polyline = AtlasPolyline;
    Rectangle = AtlasRectangle;
    Circle = AtlasCircle;
    CircleMarker = AtlasCircleMarker;
    Marker = AtlasMarker;
    Icon = AtlasIcon;
    Element = AtlasElement;
    Geocoder = MapboxAjaxGeocoder;
    LayerGroup = AtlasLayerGroup;
}
export const Path = BasePath;

// object factories
export const map = function(a1,a2,a3,a4,a5) { return new Map(a1,a2,a3,a4,a5); };
export const polygon = function(a1,a2,a3,a4,a5) { return new Polygon(a1,a2,a3,a4,a5); };
export const polyline = function(a1,a2,a3,a4,a5) { return new Polyline(a1,a2,a3,a4,a5); };
export const rectangle = function(a1,a2,a3,a4,a5) { return new Rectangle(a1,a2,a3,a4,a5); };
export const circle = function(a1,a2,a3,a4,a5) { return new Circle(a1,a2,a3,a4,a5); };
export const circleMarker = function(a1,a2,a3,a4,a5) { return new CircleMarker(a1,a2,a3,a4,a5); };
export const marker = function(a1,a2,a3,a4,a5) { return new Marker(a1,a2,a3,a4,a5); };
export const icon = function(a1,a2,a3,a4,a5) { return new Icon(a1,a2,a3,a4,a5); };
export const element = function(a1,a2,a3,a4,a5) { return new Element(a1,a2,a3,a4,a5); };
export const geocoder = function(a1,a2,a3,a4,a5) { return new Geocoder(a1,a2,a3,a4,a5); };
export const layerGroup = function(a1,a2,a3,a4,a5) { return new LayerGroup(a1,a2,a3,a4,a5); };
export const grib = function(a1,a2,a3,a4,a5) { return new Grib(a1,a2,a3,a4,a5); };


function BaseMap() {
    this.clickHandler = $j.Callbacks('unique');
    this.viewportHandler = $j.Callbacks('unique');
    this.dragStarted = $j.Callbacks('unique');
    this.dragEnded = $j.Callbacks('unique');
    this.zoomChanged = $j.Callbacks('unique');
    this.mouseOut = $j.Callbacks('unique');
    this.mouseOver = $j.Callbacks('unique');
    this.mouseUp = $j.Callbacks('unique');
    this.mouseDown = $j.Callbacks('unique');
    this.mouseMove = $j.Callbacks('unique');
}
BaseMap.prototype.isValid = function(){ return false; };
BaseMap.prototype.initialise = function(element, opts) {};
BaseMap.prototype.getBounds = function() { return null; };
BaseMap.prototype.setBounds = function(bounds) {};
BaseMap.prototype.setMaxBounds = function(bounds) {};
BaseMap.prototype.enableZoom = function() {};
BaseMap.prototype.disableZoom = function() {};
BaseMap.prototype.enableDrag = function() {};
BaseMap.prototype.disableDrag = function() {};
BaseMap.prototype.getZoom = function() { return null; };
BaseMap.prototype.setZoom = function(zoom) {};
BaseMap.prototype.getCentre = function() { return null; };
BaseMap.prototype.setCentre = function(centre) {};
BaseMap.prototype.requestLayout = function(){};
BaseMap.prototype.addItem = function(item) {};
BaseMap.prototype.containsItem = function(item) { return false; };
BaseMap.prototype.removeItem = function(item) {};
BaseMap.prototype.getBoundsSpan = function() {
    return utils.boundsSpan(this.getBounds());
};
BaseMap.prototype.defaultOptions = function(opts) {
    opts = $j.extend({}, opts || {});
    opts.lat = (typeof opts.lat == 'undefined') ? 0 : opts.lat * 1;
    opts.lon = (typeof opts.lon == 'undefined') ? 0 : opts.lon * 1;
    opts.zoom = (typeof opts.zoom == 'undefined') ? 0 : opts.zoom * 1;
    opts.minZoom = (typeof opts.minZoom == 'undefined') ? 1 : opts.minZoom * 1;
    opts.maxZoom = (typeof opts.maxZoom == 'undefined') ? 15 : opts.maxZoom * 1;
    if (typeof opts.zoomControl == 'undefined') opts.zoomControl = !pwMapIsSmartPhone;
    if (typeof opts.keyboardShortcuts == 'undefined') opts.keyboardShortcuts = true;
    if (typeof opts.mapTypeId == 'undefined') opts.mapTypeId = MAPTYPE_TERRAIN;
    if (getQueryParam('freeZoom')) {
        opts.minZoom = 0;
        opts.maxZoom = 1000;
    }
    return opts;
};

function BasePath() {
}
BasePath.prototype.defaultOptions = function(opts) {
    opts = $j.extend({}, opts || {});
    if (typeof opts.radius == 'undefined') opts.radius = 20;
    if (typeof opts.centre == 'undefined') opts.centre = { lat:0, lon:0 };
    if (typeof opts.lineColour == 'undefined') opts.lineColour = 'white';
    if (typeof opts.lineWeight == 'undefined') opts.lineWeight = 1;
    if (typeof opts.lineOpacity == 'undefined') opts.lineOpacity = 1;
    if (typeof opts.fillColour == 'undefined') opts.fillColour = 'white';
    if (typeof opts.fillOpacity == 'undefined') opts.fillOpacity = 1;
    return opts;
};
BasePath.getBounds = function(path) {
    if (window.Atlas) {
        if (path instanceof window.Atlas.Path) {
            return path.bounds;
        }
        if (path instanceof window.Atlas.Rectangle) {
            return { n:path.n, s:path.s, e:path.e, w:path.w };
        }
    }
    if (window.L) {
        let b = path.getBounds();
        if (b instanceof L.LatLngBounds) {
            return {
                s:b.getSouth(),
                n:b.getNorth(),
                w:utils.modulateLon(b.getWest()),
                e:utils.modulateLon(b.getEast())
            };
        }
    }
    if (window.google && window.google.maps) {
        let b = path.getBounds();
        if (b instanceof google.maps.LatLngBounds) {
            var sw = b.getSouthWest(), ne = b.getNorthEast();
            return {
                s:sw.lat(),
                n:ne.lat(),
                w:sw.lng(),
                e:ne.lng()
            };
        }
    }
    throw new Error("Wrong type of bounds.");
};

function BaseRectangle() {
}
BaseRectangle.prototype.defaultOptions = function(opts) {
    opts = $j.extend({}, opts || {});
    if (typeof opts.n == 'undefined') opts.n = 0;
    if (typeof opts.s == 'undefined') opts.s = 0;
    if (typeof opts.e == 'undefined') opts.e = 0;
    if (typeof opts.w == 'undefined') opts.w = 0;
    if (typeof opts.colour == 'undefined') opts.colour = "#003ff3";
    if (typeof opts.outlineOnly == 'undefined') opts.outlineOnly = false;
    if (typeof opts.hidden == 'undefined') opts.hidden = false;
    return opts;
};

function BaseMarker() {
}
BaseMarker.prototype.defaultOptions = function(opts) {
    opts = $j.extend({}, opts || {});
    if (typeof opts.position == 'undefined') opts.position = { lat:opts.lat || 0, lon:opts.lon || 0 };
    if (typeof opts.clickable == 'undefined') opts.clickable = !!opts.click;
    if (typeof opts.draggable == 'undefined') opts.draggable = !!opts.drag || !!opts.dragged;
    if (typeof opts.raiseOnDrag == 'undefined') opts.raiseOnDrag = true;
    if (typeof opts.zIndex == 'undefined') { opts.zIndex = MAX_ZINDEX; }
    if (typeof opts.title == 'undefined') opts.title = '';
    return opts;
};

function GoogleMap() {
    BaseMap.apply(this, arguments);
    this._googleMap = null;
    this._infoWindow = null;
    this._infoWindowHtml = null;
    this._infoWindowFooter = null;
}
if (pwMapDefaultAPI === 'google') {
    GoogleMap._lastKeyEvent = {};
    $j(document).on("keyup keydown", function(e) {
        GoogleMap._lastKeyEvent = e;
    });
}
GoogleMap.prototype = new BaseMap();
GoogleMap.prototype.constructor = GoogleMap;
GoogleMap.prototype.isValid = function(){
    return (this._googleMap !== null);
};
GoogleMap.prototype.initialise = function(element, opts) {
    if (typeof opts.zoomControl == 'undefined') {
        opts.zoomControl = true; // normally !pwMapIsSmartPhone, but pinch zooming doesn't work
    }
    opts = this.defaultOptions(opts);
    var mapOptions = {
        mapTypeId: opts.mapTypeId,
        center: new google.maps.LatLng(opts.lat, opts.lon),
        zoom: opts.zoom,
        minZoom: opts.minZoom,
        maxZoom: opts.maxZoom,
        keyboardShortcuts: opts.keyboardShortcuts,
        zoomControl: opts.zoomControl,
        zoomControlOptions: {
                position: google.maps.ControlPosition.LEFT_BOTTOM,
                style: google.maps.ZoomControlStyle.SMALL
            },
        scaleControl: true,
        overviewMapControl: false,
        mapTypeControl: !!getQueryParam('mapTypeControl', window.GLOBAL_mapTypeControl),
        mapTypeControlOptions: { position:google.maps.ControlPosition[window.GLOBAL_mapTypeControl] },
        panControl: false,
        rotateControl: !!getQueryParam('rotateControl'),
        streetViewControl: !!getQueryParam('streetViewControl'),
        fullscreenControl: opts.fullscreenControl,
        styles: opts.styles
    };
    this._googleMap = new google.maps.Map(element, mapOptions);
    if (this._infoWindow) {
        this._infoWindow.open(this._googleMap);
    }

    function mouseEvent(e) {
        if (e) {
            var i, ke = GoogleMap._lastKeyEvent, keys = ["shiftKey","ctrlKey","altKey","metaKey"], n=keys.length;
            e = { latlon: {lat:e.latLng.lat(), lon:e.latLng.lng()} };
            for (i=0; i<n; ++i) {
                e[keys[i]] = ke[keys[i]];
            }
        }
        return e;
    }
    var that = this;
    google.maps.event.addListener(this._googleMap, 'click', function(e){ that.clickHandler.fire({ lat: e.latLng.lat(), lon: e.latLng.lng() }); });
    google.maps.event.addListener(this._googleMap, 'bounds_changed', function(){ that.viewportHandler.fire(); });
    google.maps.event.addListener(this._googleMap, 'dragstart', function(){ that.dragStarted.fire(); });
    google.maps.event.addListener(this._googleMap, 'dragend', function(){ that.dragEnded.fire(); });
    google.maps.event.addListener(this._googleMap, 'zoom_changed', function(){ that.zoomChanged.fire(); });
    google.maps.event.addListener(this._googleMap, 'mouseout', function(e){ that.mouseOut.fire(mouseEvent(e)); });
    google.maps.event.addListener(this._googleMap, 'mouseover', function(e){ that.mouseOver.fire(mouseEvent(e)); });
    google.maps.event.addListener(this._googleMap, 'mousedown', function(e){ that.mouseDown.fire(mouseEvent(e)); });
    google.maps.event.addListener(this._googleMap, 'mouseup', function(e){ that.mouseUp.fire(mouseEvent(e)); });
    google.maps.event.addListener(this._googleMap, 'mousemove', function(e){ that.mouseMove.fire(mouseEvent(e)); });
};
GoogleMap.prototype.getBounds = function() {
    var bounds = this._googleMap && this._googleMap.getBounds();
    if (!bounds) return null;
    var b = {
        s:bounds ? bounds.getSouthWest().lat() : -90,
        w:bounds ? bounds.getSouthWest().lng() : -180,
        n:bounds ? bounds.getNorthEast().lat() : 90,
        e:bounds ? bounds.getNorthEast().lng() : 180
    };
    return b;
};
GoogleMap.prototype.setBounds = function(bounds) {
    if (!bounds) return;
    if (this._googleMap) {
        this._googleMap.fitBounds(new google.maps.LatLngBounds(new google.maps.LatLng(bounds.s, bounds.w), new google.maps.LatLng(bounds.n, bounds.e)));
    }
};
GoogleMap.prototype.getZoom = function() {
    if (!this._googleMap) return null;
    return this._googleMap.getZoom();
};
GoogleMap.prototype.setZoom = function(zoom) {
    if (!this._googleMap) return;
    this._googleMap.setZoom(zoom);
};
GoogleMap.prototype.getCentre = function() {
    if (!this._googleMap) return null;
    var center = this._googleMap.getCenter();
    return { lat:center.lat(), lon:center.lng() };
};
GoogleMap.prototype.setCentre = function(centre) {
    if (!this._googleMap) return;
    this._googleMap.setCenter(new google.maps.LatLng(centre.lat, centre.lon));
};
GoogleMap.prototype.requestLayout = function(){
    if (!this._googleMap) return;
    google.maps.event.trigger(this._googleMap, 'resize');
};
GoogleMap.prototype.addItem = function(item) {
    item.setMap(this._googleMap);
};
GoogleMap.prototype.containsItem = function(item) {
    return item.getMap() == this._googleMap;
};
GoogleMap.prototype.removeItem = function(item) {
    if (this.containsItem(item)) {
        item.setMap(null);
    }
};
GoogleMap.prototype.openPopup = function(point, html, optFooter) {
    var that = this;
    var needsOpen = false;
    if (!this._infoWindow) {
        this._infoWindow = new google.maps.InfoWindow();
        google.maps.event.addListener(this._infoWindow, 'closeclick', function(){
            if (typeof infoWindowClosedCallback != 'undefined') {
                infoWindowClosedCallback();
            }
            that._infoWindow = null;
            that._infoWindowHtml = null;
            that._infoWindowFooter = null;
        });
        needsOpen = true;
    }
    var content = this._infoWindow.getContent();
    if (!content) {
        content = document.createElement('div')
        content.style.backgroundColor = 'white';
        this._infoWindow.setContent(content);
    }
    if (this._infoWindowHtml !== html || optFooter !== this._infoWindowFooter) {
        this._infoWindowHtml = html;
        this._infoWindowFooter = optFooter;
        content.innerHTML = html;
        if (optFooter) {
            $j(content).append($j(optFooter));
        }
    }
    this._infoWindow.setPosition(new google.maps.LatLng(point.lat, point.lon));
    if (needsOpen && this._googleMap) {
        this._infoWindow.open(this._googleMap);
    }
    return content;
};
GoogleMap.prototype.closePopup = function() {
    if (this._infoWindow) {
        this._infoWindow.close();
        this._infoWindow = null;
        this._infoWindowHtml = null;
        this._infoWindowFooter = null;
    }
};
GoogleMap.prototype.hasPopup = function() {
    return !!this._infoWindow;
};
GoogleMap.prototype.enableDrag = function() {
    this._googleMap.setOptions({draggable:true});
};
GoogleMap.prototype.disableDrag = function() {
    this._googleMap.setOptions({draggable:false});
};

function GooglePolyline(opts) {
    BasePath.apply(this, arguments);
    opts = this.defaultOptions(opts);
    var path = [];
    for (var i = 0; i < opts.path.length; i++) {
        path.push(new google.maps.LatLng(opts.path[i].lat, opts.path[i].lon));
    }
    var polylineOptions = {
        path: path,
        strokeColor: opts.lineColour,
        strokeWeight: opts.lineWeight,
        strokeOpacity: opts.lineOpacity,
        clickable: false,
        geodesic: true
    };
    return new google.maps.Polyline(polylineOptions);
}
GooglePolyline.prototype = new BasePath();
GooglePolyline.setPath = function(polyline, newPath) {
    var path = [];
    for (var i = 0; i < newPath.length; i++) {
        path.push(new google.maps.LatLng(newPath[i].lat, newPath[i].lon));
    }
    polyline.setPath(path);
};

function GooglePolygon(opts, opts2) {
    BasePath.apply(this, arguments);
    var polygon = null;
    if (opts && opts instanceof google.maps.Polygon) {
        polygon = opts;
        if (typeof opts2 == 'undefined') {
            return polygon;
        } else {
            opts = opts2;
        }
    }
    opts = this.defaultOptions(opts);
    var polygonOptions = {
        strokeColor: opts.lineColour,
        strokeOpacity: opts.lineOpacity,
        strokeWeight: opts.lineWeight,
        fillColor: opts.fillColour,
        fillOpacity: opts.fillOpacity,
        geodesic: false
    };
    if ('path' in opts) {
        var path = [];
        for (var i = 0; i < opts.path.length; i++) {
            path.push(new google.maps.LatLng(opts.path[i].lat, opts.path[i].lon));
        }
        polygonOptions.paths = path;
    }
    if (polygon) {
        polygon.setOptions(polygonOptions);
    } else {
        polygonOptions.clickable = !!opts.click;
        polygon = new google.maps.Polygon(polygonOptions);
    }
    if ('click' in opts) {
        google.maps.event.clearListeners(polygon, 'click');
        if (opts.click) {
            google.maps.event.addListener(polygon, 'click', function(e){ opts.click.call(polygon, { lat:e.latLng.lat(), lon:e.latLng.lng() }); });
        }
    }
    return polygon;
}
GooglePolygon.prototype = new BasePath();
GooglePolygon.setPath = GooglePolyline.setPath;

function GoogleRectangle(opts, opts2) {
    BaseRectangle.apply(this, arguments);
    var rectangle = null;
    if (opts && opts instanceof google.maps.Rectangle) {
        rectangle = opts;
        if (typeof opts2 == 'undefined') {
            return rectangle;
        } else {
            opts = opts2;
        }
    }
    opts = this.defaultOptions(opts);
    var rectangleOptions = {
        strokeWeight: 1,
        clickable: !!opts.click,
        bounds: new google.maps.LatLngBounds(
                    new google.maps.LatLng(opts.s, opts.w),
                    new google.maps.LatLng(opts.n, opts.e)
                )
    };
    if (opts.outlineOnly) {
        rectangleOptions.strokeColor = opts.colour;
        rectangleOptions.fillOpacity = 0.0;
    } else {
        rectangleOptions.fillColor =  opts.colour;
    }

    if (rectangle) {
        rectangle.setOptions(rectangleOptions);
    } else {
        rectangle = new google.maps.Rectangle(rectangleOptions);
    }

    if (opts.click !== false) {
        google.maps.event.clearListeners(rectangle, 'click');
        if (opts.click) {
            google.maps.event.addListener(rectangle, 'click', function(e){ opts.click.call(rectangle, { lat:e.latLng.lat(), lon:e.latLng.lng() }); });
        }
    }
    return rectangle;
}
GoogleRectangle.prototype = new BaseRectangle();
GoogleRectangle.setBounds = function(rect, b) {
    rect.setBounds(new google.maps.LatLngBounds(
        new google.maps.LatLng(b.s, b.w),
        new google.maps.LatLng(b.n, b.e)
    ));
};

function GoogleCircle(opts) {
    BasePath.apply(this, arguments);
    opts = this.defaultOptions(opts);
    var circleOptions = {
        radius: opts.radius,
        center: new google.maps.LatLng(opts.centre.lat, opts.centre.lon),
        strokeColor: opts.lineColour,
        strokeOpacity: opts.lineOpacity,
        strokeWeight: opts.lineWeight,
        fillColor: opts.fillColour,
        fillOpacity: opts.fillOpacity,
        clickable: false
    };
    return new google.maps.Circle(circleOptions);
}
GoogleCircle.prototype = new BasePath();

function GoogleIcon(opts, icon) {
    if (Icon.isInstance(opts)) {
        [icon, opts] = [opts, icon];
    }
    if (icon && $j.isEmptyObject(opts || {})) {
        return icon;
    }
    if (typeof opts.img == 'undefined') opts.img = icon? icon.url : '';
    if (!opts.img && opts.className) {
        // In Leaflet, we just make a DivIcon using the className.
        // Here we need to turn the background image for the className into an img.
        var d = $j('<div>').addClass(opts.className).appendTo(document.body);
        // http://stackoverflow.com/questions/8809876/can-i-get-divs-background-image-url
        var bg_url = d.css('background-image');
        d.remove();
        // ^ Either "none" or url("...urlhere..")
        bg_url = /^url\((['"]?)(.*)\1\)$/.exec(bg_url);
        bg_url = bg_url ? bg_url[2] : ""; // If matched, retrieve url, otherwise ""
        opts.img = bg_url;
    }
    if (!icon && opts.img) {
        icon = new google.maps.MarkerImage(opts.img);
    }
    if (icon) {
        icon.url = opts.img;
        if (opts.width !== undefined && opts.height !== undefined) {
            icon.size = new google.maps.Size(opts.width, opts.height);
        }
        if (opts.anchorX !== undefined && opts.anchorY !== undefined) {
            icon.anchor = new google.maps.Point(opts.anchorX, opts.anchorY);
        }
        if (opts.originX !== undefined && opts.originY !== undefined) {
            icon.origin = new google.maps.Point(opts.originX, opts.originY);
        }
        return icon;
    }
    return {url: "url-of-non-existent-image"};
}
GoogleIcon.isInstance = function(icon) {
    return icon ? (icon instanceof google.maps.MarkerImage) : false;
};

function GoogleCircleMarker(opts, marker, returnOpts) {
    if (typeof opts.radius == 'undefined') opts.radius = 4.5;
    if (typeof opts.fillColour == 'undefined') opts.fillColour = 'white';
    if (typeof opts.fillOpacity == 'undefined') opts.fillOpacity = 1;
    if (typeof opts.lineColour == 'undefined') opts.lineColour = 'black';
    if (typeof opts.lineOpacity == 'undefined') opts.lineOpacity = 1;
    if (typeof opts.lineWeight == 'undefined') opts.lineWeight = 1;
    opts.icon = {
        path: google.maps.SymbolPath.CIRCLE,
        scale: opts.radius,
        fillColor: opts.fillColour,
        fillOpacity: opts.fillOpacity,
        strokeColor: opts.lineColour,
        strokeOpacity: opts.lineOpacity,
        strokeWeight: opts.lineWeight
    };
    return new GoogleMarker(opts);
}

function GoogleMarker(opts, marker, returnOpts) {
    if (Marker.isInstance(opts)) {
        [marker, opts] = [opts, marker];
    }
    if (marker && $j.isEmptyObject(opts || {})) {
        return marker;
    }
    if (marker && !opts.title) {
        opts.title = marker.getTitle();
    }
    if (!opts.icon) {
        opts.icon = icon(opts, marker ? marker.getIcon() : undefined);
    }
    BaseMarker.apply(this, [opts, marker]);
    opts = this.defaultOptions(opts);
    if (returnOpts) {
        $j.extend(returnOpts, opts);
    }
    var markerOptions = {
        icon: opts.icon,
        position: new google.maps.LatLng(opts.position.lat, opts.position.lon),
        clickable: opts.clickable,
        draggable: opts.draggable,
        raiseOnDrag: opts.raiseOnDrag,
        zIndex: opts.zIndex,
        title: opts.title
    };
    if (marker) {
        marker.setOptions(markerOptions);
    } else {
        marker = new google.maps.Marker(markerOptions);
    }
    if (opts.click) {
        google.maps.event.clearListeners(marker, 'click');
        if (opts.click) {
            google.maps.event.addListener(marker, 'click', function(e){ opts.click.call(marker, { lat:e.latLng.lat(), lon:e.latLng.lng() }); });
        }
    }
    if (opts.drag) {
        google.maps.event.clearListeners(marker, 'drag');
        if (opts.drag) {
            google.maps.event.addListener(marker, 'drag', function(e){ opts.drag.call(marker, { lat:e.latLng.lat(), lon:e.latLng.lng() }); });
        }
    }
    if (opts.dragged) {
        google.maps.event.clearListeners(marker, 'dragend');
        if (opts.dragged) {
            google.maps.event.addListener(marker, 'dragend', function(e){ opts.dragged.call(marker, { lat:e.latLng.lat(), lon:e.latLng.lng() }); });
        }
    }
    return marker;
}
GoogleMarker.prototype = new BaseMarker();
GoogleMarker.isInstance = function(marker) {
    return marker ? (marker instanceof google.maps.Marker) : false;
};
GoogleMarker.getIcon = function(marker) {
    return marker ? marker.getIcon() : null;
};
GoogleMarker.getIcon = function(marker) {
    return marker ? marker.getIcon() : null;
};
GoogleMarker.setIcon = function(marker, opts) {
    if (!marker || !opts) return;
    if (marker.getIcon() !== opts) {
        let _icon = icon(opts);
        marker.setIcon(_icon);
    }
};
GoogleMarker.getPosition = function(marker) {
    var p = marker.getPosition();
    return { lat:p.lat(), lon:p.lng() };
};
GoogleMarker.setPosition = function(marker, opts) {
    if (typeof opts == 'undefined') opts = { };
    if (typeof opts.lat == 'undefined') opts.lat = 0;
    if (typeof opts.lon == 'undefined') opts.lon = 0;
    var position = marker.getPosition();
    if (!position || position.lat() !== opts.lat || position.lng() !== opts.lon) {
        marker.setPosition(new google.maps.LatLng(opts.lat, opts.lon));
    }
};
GoogleMarker.setVisible = function(marker, visible) {
    if (marker) {
        marker.setVisible(!!visible);
    }
};


function GoogleElement(opts, element, returnOpts) {
    if (Element.isInstance(opts)) {
        [element, opts] = [opts, element];
    }
    if (element && $j.isEmptyObject(opts || {})) {
        return element;
    }
    if (!element) {
        element = this;
    }
    if (typeof opts == 'undefined') opts = { };
    if (typeof opts.lat == 'undefined') opts.lat = element? element.pt_.lat() : 0;
    if (typeof opts.lon == 'undefined') opts.lon = element? element.pt_.lng() : 0;
    if (typeof opts.elem == 'undefined') opts.elem = element? element.elem_ : document.createElement('DIV');
    if (typeof opts.hidden == 'undefined') opts.hidden = false;
    if (returnOpts) {
        $j.extend(returnOpts, opts);
    }
    element.pt_ = new google.maps.LatLng(opts.lat, opts.lon);
    element.elem_ = opts.elem;
    element.div_ = null;

    var map = element.getMap();
    if (!opts.hidden && map) {
        element.setMap(null);
        element.setMap(map);
    }
    return element;
}
if (hasGoogleMaps) {
    GoogleElement.prototype = new google.maps.OverlayView();
}
GoogleElement.prototype.onAdd = function() {
  // Note: an overlay's receipt of onAdd() indicates that
  // the map's panes are now available for attaching
  // the overlay to the map via the DOM.
  // Create the DIV and set some basic attributes.
  var div = document.createElement('DIV');
  div.style.border = "none";
  div.style.borderWidth = "0px";
  div.style.position = "absolute";

  div.appendChild(this.elem_);

  // Set the overlay's div_ property to this DIV
  this.div_ = div;

  // We add an overlay to a map via one of the map's panes.
  // We'll add this overlay to the overlayImage pane.
  var panes = this.getPanes();
  panes.floatPane.appendChild(div);
};
GoogleElement.prototype.setPosition = function(pt) {
    this.pt_ = pt;
    this.draw();
};
GoogleElement.prototype.draw = function() {
    var overlayProjection = this.getProjection();
    if (!overlayProjection || !this.div_) {
        return;
    }
    var p = overlayProjection.fromLatLngToDivPixel(this.pt_);

    // Resize the image's DIV to fit the indicated dimensions.
    var div = this.div_;
    div.style.left = p.x + 'px';
    div.style.top = p.y + 'px';
};
GoogleElement.prototype.onRemove = function() {
    if (this.div_) {
        this.div_.parentNode.removeChild(this.div_);
        this.div_ = null;
    }
};
GoogleElement.setPosition = function(element, position) {
    element.pt_ = position;
    element.draw();
};
GoogleElement.isInstance = function(element) {
    return element ? (element instanceof GoogleElement) : false;
};

function GoogleLayerGroup(layers) {
    var theMap = null;
    if (layers) {
        layers = layers.slice();
    } else {
        layers = [];
    }
    this.setMap = function(map) {
        theMap = map;
        for (var i=0; i<layers.length; ++i) {
            layers[i].setMap(map);
        }
    };
    this.getMap = function() {
        return theMap;
    };
    this.addItem = function(item) {
        if (!this.containsItem(item)) {
            layers.push(item);
            item.setMap(theMap);
        }
    };
    this.containsItem = function(item) {
        for (var i=0; i<layers.length; ++i) {
            if (layers[i] === item) {
                return true;
            }
        }
        return false;
    };
    this.removeItem = function(item) {
        for (var i=0; i<layers.length; ++i) {
            if (layers[i] === item) {
                layers.splice(i,1);
                item.setMap(null);
                return;
            }
        }
    };
    this.eachItem = function(callback) {
        var layersCopy = layers.slice();
        for (var i = 0; i < layersCopy.length; i++) {
            if (callback(layersCopy[i]) === false) {
                break;
            }
        }
    };
}
GoogleLayerGroup.isInstance = function(group){
    return group ? (group instanceof GoogleLayerGroup) : false;
};
GoogleLayerGroup.addItem = function(group, item) {
    group.addItem(item);
};
GoogleLayerGroup.removeItem = function(group, item) {
    group.removeItem(item);
};
GoogleLayerGroup.containsItem = function(group, item) {
    return group.containsItem(item);
};
GoogleLayerGroup.eachItem = function(group, callback) {
    return group.eachItem(callback);
};

function GoogleGeocoder() {
    this._geocoder = new google.maps.Geocoder();
    this.search = function(searchText, callback){
        this._geocoder.geocode({ address:searchText }, function(googleResults, status) {
            var results = null;
            if (status == google.maps.GeocoderStatus.OK && googleResults && googleResults.length) {
                results = [];
                for (var i = 0; i < googleResults.length; i++) {
                    var googleResult = googleResults[i];
                    var location = googleResult.geometry.location;
                    var bounds = googleResult.geometry.viewport;
                    var result = {
                        b:{
                            s:bounds.getSouthWest().lat(),
                            w:bounds.getSouthWest().lng(),
                            n:bounds.getNorthEast().lat(),
                            e:bounds.getNorthEast().lng()
                        },
                        lat:location.lat(),
                        lon:location.lng(),
                        zoom:SEARCH_ZOOM_DEFAULT
                    };
                    results.push(result);
                }
            }
            callback(results);
        });
    };
}

function GoogleAjaxGeocoder() {
    this.search = function(searchText, callback){
        var baseUrl = '//maps.googleapis.com/maps/api/geocode/json?sensor=' + (pwMapIsSmartPhone ? 'true' : 'false') + '&address=';
        var url = baseUrl + encodeURIComponent(searchText);
        $j.get(url, function(response) {
            var results = null;
            var searchResults = response && response.results;
            if (searchResults && searchResults.length) {
                results = [];
                for (var i = 0; i < searchResults.length; i++) {
                    var searchResult = searchResults[i];
                    var result = {
                        b:{
                            s:searchResult.geometry.viewport.southwest.lat * 1,
                            n:searchResult.geometry.viewport.northeast.lat * 1,
                            w:searchResult.geometry.viewport.southwest.lng * 1,
                            e:searchResult.geometry.viewport.northeast.lng * 1
                        },
                        lat:searchResult.geometry.location.lat * 1,
                        lon:searchResult.geometry.location.lng * 1,
                        zoom:SEARCH_ZOOM_DEFAULT,
                        formatted_address:searchResult.formatted_address
                    };
                    results.push(result);
                }
            }
            callback(results);
        }, 'json');
    };
}

function MapboxAjaxGeocoder() {
    this.search = function(searchText, callback, any_country){ // forward geocoding
        var that = this;
        var baseUrl = 'https://api.mapbox.com/geocoding/v5/mapbox.places/';
        searchText = searchText.replace(/;/g, ",");
        if (/,/.test(searchText)) {
            any_country = true;
        }
        var url = baseUrl + encodeURIComponent(searchText) + '.json';
        var data = {
            access_token: mapBoxToken,
            autocomplete: "false",
            types: "region,postcode,district,place,locality,neighborhood",
            limit: "1"
        };
        if (any_country) {
            data['types'] = 'country,' + data['types'];
        } else {
            data['country'] = $j(document.body).data('countryCode');
        }
        var coords = $j(document.body).data('coords');
        if (coords) {
            data['proximity'] = '' + coords.longitude + ',' + coords.latitude;
        }
        $j.ajax(url, {
            data: data,
            dataType: 'json'
        }).done(function(response) {
            var results = null;
            var searchResults = response && response["features"];
            if (searchResults && searchResults.length) {
                results = [];
                for (var i = 0; i < searchResults.length; i++) {
                    var feature = searchResults[i];
                    var result = {
                        lat: feature.center[1] * 1,
                        lon: feature.center[0] * 1,
                        zoom: SEARCH_ZOOM_DEFAULT,
                        formatted_address: feature.address || feature.properties && feature.properties.address
                    };
                    if (feature.bbox) {
                        result.b = {
                            s: feature.bbox[1] * 1,
                            n: feature.bbox[3] * 1,
                            w: feature.bbox[0] * 1,
                            e: feature.bbox[2] * 1
                        };
                    }
                    results.push(result);
                }
            } else if (!any_country) {
                that.search(searchText, callback, true);
                return;
            }

            callback(results);
        });
    };
    this.reverseSearch = function(lon, lat, callback, types){ // reverse geocoding
        var baseUrl = 'https://api.mapbox.com/geocoding/v5/mapbox.places/';
        var url = baseUrl + encodeURIComponent(lon) + ',' + encodeURIComponent(lat) + '.json';
        types = types || "country,region,postcode,district,place,locality,neighborhood";
        var data = {
            access_token: mapBoxToken,
            types:types,
            limit: "1",
        };
        $j.ajax(url, {
            data: data,
            dataType: 'json'
        }).done(function(response) {
            var results = null;
            var searchResults = response && response["features"];
            if (searchResults && searchResults.length) {
                results = searchResults;
            }
            callback(results);
        });
    };
}


function LeafletMap() {
    BaseMap.call(this);
    this._leafletMap = null;
    this._popup = null;
    this._popupHtml = null;
    this._popupFooter = null;
}
LeafletMap.prototype = new BaseMap();
LeafletMap.prototype.constructor = LeafletMap;
LeafletMap.prototype.isValid = function(){
    return (this._leafletMap !== null);
};
LeafletMap.prototype.initialise = function(element, opts) {
    var that = this;
    opts = this.defaultOptions(opts);
    var mapOptions = {
        center: new L.LatLng(opts.lat, opts.lon),
        zoom: opts.zoom,
        minZoom: opts.minZoom,
        maxZoom: opts.maxZoom,
        maxBounds: opts.maxBounds ? [[opts.maxBounds.s, opts.maxBounds.w], [opts.maxBounds.n, opts.maxBounds.e]] : null,
        keyboard: opts.keyboardShortcuts,
        attributionControl: false,
        zoomControl: false,
        fadeAnimation:false,
        inertiaDeceleration:10000
    };
    this._leafletMap = L.map(element, mapOptions);
    this._pointItems = [];
    this._shapeItems = [];
    this._needsClones = false;

    this._leafletMap.addControl(L.control.scale({ position:'bottomleft' }));
    if (opts.zoomControl) {
        this._leafletMap.addControl(L.control.zoom({ position:'bottomleft' }));
    }

    var tileLayerOptions = {};
    tileLayerOptions.unloadInvisibleTiles = false;
    tileLayerOptions.updateWhenIdle = false;
    tileLayerOptions.detectRetina = true;
    tileLayerOptions.minZoom = opts.minZoom;
    tileLayerOptions.maxZoom = opts.maxZoom;
    tileLayerOptions.maxNativeZoom = opts.maxZoom;
    if (legalInformation) {
        this._leafletMap.addControl(new LeafletAttribution({ position:'bottomright' }));
        tileLayerOptions.attribution = legalInformation;
    }
    var tileLayerUrl = getSetting('Maps_TileOverrideUrl', getDefaultTileLayerUrl());
    var tileLayer = L.tileLayer(tileLayerUrl, tileLayerOptions);
    if (tileLayerOptions.detectRetina && tileLayerOptions.maxNativeZoom) {
        // retina/maxNativeBounds causes an internal maxZoom--, which is wrong.
        tileLayer.options.maxZoom = opts.maxZoom;
    }
    tileLayer.addTo(this._leafletMap);
    if (this._popup) {
        this._popup.openOn(this._leafletMap);
    }

    var dragging = false;
    this._wrapLon = function(lon) {
        var mapCenter = that._leafletMap.getCenter();
        while (lon < mapCenter.lng - 180) lon += 360;
        while (lon > mapCenter.lng + 180) lon -= 360;
        return lon;
    };

    this._wrapPointItem = function(item) {
        var latLng = item.getLatLng();
        if (!latLng || !that._leafletMap || item._isClone) return;
        var mapCenter = that._leafletMap.getCenter();
        var deltaLng = 0;
        while (latLng.lng + deltaLng < mapCenter.lng - 180) deltaLng += 360;
        while (latLng.lng + deltaLng > mapCenter.lng + 180) deltaLng -= 360;
        if (deltaLng) {
            item.setLatLng(L.latLng(latLng.lat, latLng.lng + deltaLng));
        }
        if (item._markerClone) {
            var bounds = this._leafletMap.getBounds();
            var cloneLatLng = L.latLng(latLng.lat, latLng.lng + deltaLng + 360);
            if (bounds && bounds.isValid && !bounds.contains(cloneLatLng)) {
                cloneLatLng = L.latLng(latLng.lat, latLng.lng + deltaLng - 360);
            }
            item._markerClone.setLatLng(cloneLatLng);
        }
    };
    this._wrapShapeItem = function(item) {
        var latLngs = item.getLatLngs();
        if (!latLngs || !latLngs.length || !that._leafletMap) {
            return;
        }
        var bounds = null;
        for (let n = 0; n < latLngs.length; n++) {
            bounds = utils.boundsExtend(bounds, { lat:latLngs[n].lat, lon:latLngs[n].lng });
        }
        var centre = utils.boundsCentre(bounds);
        var mapCenter = that._leafletMap.getCenter();
        var deltaLng = 0;
        while (centre.lon + deltaLng < mapCenter.lng - 180) deltaLng += 360;
        while (centre.lon + deltaLng > mapCenter.lng + 180) deltaLng -= 360;

        var westFromCentre = bounds.w - centre.lon;
        if (westFromCentre > 0) westFromCentre -= 360;
        var newLatLngs = [];
        var changed = false;
        for (let n = 0; n < latLngs.length; n++) {
            var lonFromWest = utils.modulateLon(latLngs[n].lng) - bounds.w;
            if (lonFromWest < 0) lonFromWest += 360;
            var lon = lonFromWest + westFromCentre + centre.lon + deltaLng;
            if (!changed) {
                changed = (Math.abs(lon - latLngs[n].lng) > 180);
            }
            newLatLngs.push(L.latLng(latLngs[n].lat, lon));
        }
        if (changed) {
            item.setLatLngs(newLatLngs);
        }
    };
    function viewportHandler() {
        var bounds = that._leafletMap.getBounds();
        var needsClones = bounds && bounds.isValid() && (bounds.getEast() - bounds.getWest() >= 360);
        if (needsClones !== that._needsClones) {
            that._needsClones = needsClones;
            var items = that._pointItems.slice();
            for (var i=0; i<items.length; i++) {
                that.removeItem(items[i]);
                that.addItem(items[i]);
            }
        }
        that.viewportHandler.fire();
    }
    function layerAddedHandler(e) {
        if (e.layer._isClone) {
            return;
        }
        if (that._needsClones && LeafletMarker.isInstance(e.layer)) {
            if (!e.layer._markerClone) {
                var latLng = e.layer.getLatLng();
                e.layer._markerClone = L.marker(L.latLng(latLng.lat, latLng.lng + 360), e.layer.options);
                e.layer._markerClone._isClone = true;
                e.layer._markerClone.addTo(that._leafletMap);
            }
        }

        e.layer._PW_MAP = that;
        if (e.layer.getLatLngs && e.layer.setLatLngs) {
            if ($j.inArray(e.layer, this._shapeItems) === -1) {
                that._shapeItems.push(e.layer);
                that._wrapShapeItem(e.layer);
            }
        } else if (e.layer.getLatLng && e.layer.setLatLng) {
            if ($j.inArray(e.layer, this._pointItems) === -1) {
                that._pointItems.push(e.layer);
                that._wrapPointItem(e.layer);
            }
        }
    }
    function layerRemovedHandler(e) {
        if (e.layer._isClone) {
            return;
        }
        if (e.layer._markerClone) {
            that._leafletMap.removeLayer(e.layer._markerClone);
            e.layer._markerClone = null;
        }

        if (e.layer._PW_MAP === that) {
            e.layer._PW_MAP = null;
        }
        var index = $j.inArray(e.layer, that._shapeItems)
        if (index !== -1){
            that._shapeItems.splice(index, 1);
        } else {
            index = $j.inArray(e.layer, that._pointItems)
            if (index !== -1){
                that._pointItems.splice(index, 1);
            }
        }
    }
    function popupClosedHandler() {
        if (typeof infoWindowClosedCallback != 'undefined') {
            infoWindowClosedCallback();
        }
        that._popup = null;
        that._popupHtml = null;
        that._popupFooter = null;
    }
    function clickHandler(e) {
        that.clickHandler.fire({ lat: e.latlng.lat, lon: e.latlng.lng });
    }
    function dragStartHandler() {
        dragging = true;
        that.dragStarted.fire();
    }
    function dragEndHandler() {
        setTimeout(viewportHandler, 0);
        dragging = false;
        for (let i = 0; i < that._pointItems.length; i++) {
            that._wrapPointItem(that._pointItems[i]);
        }
        for (let i = 0; i < that._shapeItems.length; i++) {
            that._wrapShapeItem(that._shapeItems[i]);
        }
        that.dragEnded.fire();
    }
    function zoomChangedHandler() {
        that.zoomChanged.fire();
    }
    function mouseEvent(e) {
        if (e) {
            var i, oe = e.originalEvent, keys = ["shiftKey","ctrlKey","altKey","metaKey"], n=keys.length;
            e = {latlon: {lat:e.latlng.lat, lon:e.latlng.lng}};
            for (i=0; i<n; ++i) {
                e[keys[i]] = oe[keys[i]];
            }
        }
        return e;
    }
    function mouseOutHandler(e) {
        that.mouseOut.fire(mouseEvent(e));
    }
    function mouseOverHandler(e) {
        that.mouseOver.fire(mouseEvent(e));
    }
    function mouseUpHandler(e) {
        that.mouseUp.fire(mouseEvent(e));
    }
    function mouseDownHandler(e) {
        that.mouseDown.fire(mouseEvent(e));
    }
    function mouseMoveHandler(e) {
        that.mouseMove.fire(mouseEvent(e));
    }

    this._leafletMap.on('click', clickHandler);
    this._leafletMap.on('move', viewportHandler);
    this._leafletMap.on('zoomend', viewportHandler);
    this._leafletMap.on('dragstart', dragStartHandler);
    this._leafletMap.on('dragend', dragEndHandler);
    this._leafletMap.on('zoomend', zoomChangedHandler);
    this._leafletMap.on('mouseout', mouseOutHandler);
    this._leafletMap.on('mouseover', mouseOverHandler);
    this._leafletMap.on('mouseup', mouseUpHandler);
    this._leafletMap.on('mousedown', mouseDownHandler);
    this._leafletMap.on('mousemove', mouseMoveHandler);
    this._leafletMap.on('popupclose', popupClosedHandler);
    this._leafletMap.on('layeradd', layerAddedHandler);
    this._leafletMap.on('layerremove', layerRemovedHandler);


    // simulate viewport event
    setTimeout(viewportHandler, 200);
};
LeafletMap.prototype.getBounds = function() {
    var bounds = this._leafletMap && this._leafletMap.getBounds();
    if (!bounds) return null;
    var b = {
        s:bounds.isValid() ? bounds.getSouth() : -90,
        w:bounds.isValid() ? utils.modulateLon(bounds.getWest()) : -180,
        n:bounds.isValid() ? bounds.getNorth() : 90,
        e:bounds.isValid() ? utils.modulateLon(bounds.getEast()) : 180
    };
    if (bounds.isValid() && bounds.getEast() - bounds.getWest() >= 360) {
        b.w = -180;
        b.e = 180;
    }
    return b;
};

LeafletMap.prototype.setBounds = function(bounds) {
    if (!bounds) return;
    if (this._leafletMap) {
        var east = utils.modulateLon(bounds.e);
        var west = utils.modulateLon(bounds.w);
        if (east < west) {
            east += 360;
        }
        this._leafletMap.fitBounds([[bounds.s, west], [bounds.n, east]]);
    }
};
LeafletMap.prototype.setMaxBounds = function(bounds) {
    if (!bounds) {
        this._leafletMap.setMaxBounds(null);
        return;
    }
    if (this._leafletMap) {
        var east = utils.modulateLon(bounds.e);
        var west = utils.modulateLon(bounds.w);
        if (east < west) {
            east += 360;
        }
        this._leafletMap.setMaxBounds([[bounds.s, west], [bounds.n, east]]);
    }
};
LeafletMap.prototype.enableZoom = function() {
    if (this._zoomDisabled) {
        L.Util.setOptions(this._leafletMap, this._zoomDisabled);
        this._zoomDisabled = null;
    }
};
LeafletMap.prototype.disableZoom = function() {
    if (!this._zoomDisabled) {
        this._zoomDisabled = {
            minZoom:this._leafletMap.getMinZoom(),
            maxZoom:this._leafletMap.getMaxZoom()
        };
        L.Util.setOptions(this._leafletMap, {
            minZoom:this._leafletMap.getZoom(),
            maxZoom:this._leafletMap.getZoom()
        });
    }
};
LeafletMap.prototype.enableDrag = function() {
    this._leafletMap.dragging.enable();
};
LeafletMap.prototype.disableDrag = function() {
    this._leafletMap.dragging.disable();
};
LeafletMap.prototype.getZoom = function() {
    if (!this._leafletMap) return null;
    return this._leafletMap.getZoom();
};
LeafletMap.prototype.setZoom = function(zoom) {
    if (!this._leafletMap) return;
    this._leafletMap.setZoom(zoom, { animate:false });
};
LeafletMap.prototype.getCentre = function() {
    if (!this._leafletMap) return null;
    var center = this._leafletMap.getCenter();
    return { lat:center.lat, lon:utils.modulateLon(center.lng) };
};
LeafletMap.prototype.setCentre = function(centre) {
    if (!this._leafletMap) return;
    this._leafletMap.panTo(new L.LatLng(centre.lat, centre.lon), { animate:false });
};
LeafletMap.prototype.requestLayout = function() {
    if (!this._leafletMap) return;
    this._leafletMap.invalidateSize(true);
};
LeafletMap.prototype.addItem = function(item) {
    if (this._leafletMap) {
        if (item.addTo) {
            item.addTo(this._leafletMap);
        }
        if (LeafletElement.isInstance(item)) {
            this._leafletMap.addLayer(item);
        }
        item._PW_MAP = this;
    }
};
LeafletMap.prototype.containsItem = function(item) {
    return (item._PW_MAP === this);
};
LeafletMap.prototype.removeItem = function(item) {
    if (this.containsItem(item)) {
        if (item._PW_MAP === this) {
            item._PW_MAP = null;
        }
        if (this._leafletMap) {
            this._leafletMap.removeLayer(item);
        }
    }
};
LeafletMap.prototype.openPopup = function(point, html, optFooter) {
    var that = this;
    var needsOpen = false;
    if (!this._popup) {
        this._popup = L.popup({maxWidth:600});
        needsOpen = true;
    }
    var content = this._popup.getContent();
    if (!content) {
        content = document.createElement('div');
        content.style.backgroundColor = 'white';
        this._popup.setContent(content);
    }
    if (this._popupHtml != html || optFooter !== this._popupFooter) {
        this._popupHtml = html;
        this._popupFooter = optFooter;
        content.innerHTML = html;
        if (optFooter) {
            $j(content).append($j(optFooter));
        }
    }
    this._popup.setLatLng(new L.LatLng(point.lat, this._wrapLon(point.lon)));
    if (this._leafletMap) {
        if (needsOpen) {
            this._popup.openOn(this._leafletMap);
        }
    }
    return content;
};
LeafletMap.prototype.closePopup = function() {
    if (this._popup) {
        if (this._leafletMap) {
            this._leafletMap.closePopup(this._popup);
        }
        this._popup = null;
        this._popupHtml = null;
        this._popupFooter = null;
    }
};
LeafletMap.prototype.hasPopup = function() {
    return !!this._popup;
};

function LeafletPolyline(opts) {
    BasePath.apply(this, arguments);
    opts = this.defaultOptions(opts);
    var path = [];
    for (var i = 0; i < opts.path.length; i++) {
        path.push(new L.LatLng(opts.path[i].lat, opts.path[i].lon));
    }
    var polylineOptions = {
        color: opts.lineColour,
        weight: opts.lineWeight,
        opacity: opts.lineOpacity,
        clickable: false
    };
    return new L.Polyline(path, polylineOptions);
}
LeafletPolyline.prototype = new BasePath();
LeafletPolyline.setPath = function(polyline, newPath) {
    var path = [];
    for (var i = 0; i < newPath.length; i++) {
        path.push(new L.LatLng(newPath[i].lat, newPath[i].lon));
    }
    polyline.setLatLngs(path);
    if (polyline._PW_MAP) {
        polyline._PW_MAP._wrapShapeItem(polyline);
    }
};

function LeafletPolygon(opts, opts2) {
    BasePath.apply(this, arguments);
    var polygon = null;
    if (opts && opts instanceof L.Polygon) {
        polygon = opts;
        if (typeof opts2 == 'undefined') {
            return polygon;
        } else {
            opts = opts2;
        }
    }
    opts = this.defaultOptions(opts);
    var polygonOptions = {
        color: opts.lineColour,
        weight: opts.lineWeight,
        opacity: opts.lineOpacity,
        fillColor: opts.fillColour,
        fillOpacity: opts.fillOpacity
    };
    // Leaflet doesn't support changing clickable, so only obey for new objects
    if (!polygon) {
        polygonOptions.clickable = !!opts.click;
    }
    var path;
    if ('path' in opts) {
        path = [];
        for (var i = 0; i < opts.path.length; i++) {
            path.push(new L.LatLng(opts.path[i].lat, opts.path[i].lon));
        }
    }
    if (polygon) {
        polygon.setStyle(polygonOptions);
        if (path !== undefined) {
            polygon.setLatLngs(path);
        }
    } else {
        polygon = new L.Polygon(path, polygonOptions);
    }
    // If 'click' is not explicitly supplied, leave any existing handler in place
    if ('click' in opts) {
        polygon.clearAllEventListeners();
        if (opts.click) {
            polygon.on('click', function(e){ opts.click.call(polygon, { lat:e.latlng.lat, lon:e.latlng.lng }); });
        }
    }
    return polygon;
}
LeafletPolygon.prototype = new BasePath();
LeafletPolygon.setPath = LeafletPolyline.setPath;

function LeafletRectangle(opts, opts2) {
    BaseRectangle.apply(this, arguments);
    var rectangle = null;
    if (opts && opts instanceof L.Rectangle) {
        rectangle = opts;
        if (typeof opts2 == 'undefined') {
            return rectangle;
        } else {
            opts = opts2;
        }
    }
    opts = this.defaultOptions(opts);
    while (opts.e < opts.w) {
        opts.e += 360;
    }
    var rectangleBounds = [[opts.s, opts.w], [opts.n, opts.e]];
    var rectangleOptions = {
        color: opts.lineColour,
        weight: opts.lineWeight,
        opacity: opts.lineOpacity,
        noClip: true
    };
    if (opts.outlineOnly) {
        rectangleOptions.color = opts.colour;
        rectangleOptions.fillOpacity = 0.0;
    } else {
        rectangleOptions.fillColor =  opts.colour;
    }
    if (rectangle) {
        rectangle.setStyle(rectangleOptions);
    } else {
        rectangle = new L.Rectangle(rectangleBounds, rectangleOptions);
    }
    // Leaflet doesn't support changing clickable, so only obey for new objects
    if (!rectangle) {
        rectangleOptions.clickable = !!opts.click;
    }
    // If 'click' is not explicitly supplied, leave any existing handler in place
    if ('click' in opts) {
        rectangle.clearAllEventListeners();
        if (opts.click) {
            rectangle.on('click', function(e){ opts.click.call(rectangle, { lat:e.latlng.lat, lon:e.latlng.lng }); });
        }
    }
    return rectangle;
}
LeafletRectangle.prototype = new BaseRectangle();
LeafletRectangle.setBounds = function(rect, b) {
    rect.setBounds(L.latLngBounds(L.latLng(b.s, b.w), L.latLng(b.n, b.e)));
    if (rect._PW_MAP) {
        rect._PW_MAP._wrapShapeItem(rect);
    }
};

function LeafletCircle(opts) {
    BasePath.apply(this, arguments);
    opts = this.defaultOptions(opts);
    var center = new L.LatLng(opts.centre.lat, opts.centre.lon);
    var radius = opts.radius;
    var circleOptions = {
        color: opts.lineColour,
        weight: opts.lineWeight,
        opacity: opts.lineOpacity,
        fillColor: opts.fillColour,
        fillOpacity: opts.fillOpacity,
        clickable: false
    };
    return new L.Circle(center, radius, circleOptions);
}
LeafletCircle.prototype = new BasePath();

function LeafletMarker(opts, marker, returnOpts) {
    if (CircleMarker.isInstance(opts) || CircleMarker.isInstance(marker)) {
        return LeafletCircleMarker(opts, marker, returnOpts);
    }
    if (Marker.isInstance(opts)) {
        [marker, opts] = [opts, marker];
    }
    if (marker && $j.isEmptyObject(opts || {})) {
        return marker;
    }
    if (!opts.icon) {
        opts.icon = icon(opts, marker ? marker._PW_ICON : undefined);
    }
    BaseMarker.apply(this, [opts, marker]);
    opts = this.defaultOptions(opts);
    if (returnOpts) {
        $j.extend(returnOpts, opts);
    }
    var markerOptions = {
        icon: opts.icon,
        clickable: opts.clickable || opts.draggable,
        draggable: opts.draggable,
        riseOnHover: opts.raiseOnDrag,
        zIndexOffset: opts.zIndex,
        keyboard: false,
        title: opts.title
    };
    if (marker) {
        LeafletMarker.setPosition(marker, opts.position);
        LeafletMarker.setIcon(marker, opts.icon);
    } else {
        marker = new L.Marker(new L.LatLng(opts.position.lat, opts.position.lon), markerOptions);
        marker._PW_ICON = markerOptions.icon;
        if (opts.click) {
            marker.on('click', function(){ opts.click.call(marker, { lat:marker.getLatLng().lat, lon:marker.getLatLng().lng }); });
        }
        if (opts.drag) {
            marker.on('drag', function(){ opts.drag.call(marker, { lat:marker.getLatLng().lat, lon:marker.getLatLng().lng }); });
        }
        if (opts.dragged) {
            marker.on('dragend', function(){ opts.dragged.call(marker, { lat:marker.getLatLng().lat, lon:marker.getLatLng().lng }); });
        }
    }
    return marker;
}
LeafletMarker.prototype = new BaseMarker();
LeafletMarker.isInstance = function(marker) {
    return marker ? (marker instanceof L.Marker) : false;
};
LeafletMarker.getIcon = function(marker) {
    return marker ? marker._PW_ICON : null;
};
LeafletMarker.setIcon = function(marker, opts) {
    if (!marker || !opts) return;
    if (marker._PW_ICON !== opts) {
        let _icon = icon(opts);
        marker.setIcon(_icon);
        marker._PW_ICON = _icon;
        if (marker._markerClone) {
            marker._markerClone.setIcon(_icon);
        }
    }
};
LeafletMarker.getPosition = function(marker) {
    var p = marker.getLatLng();
    return { lat:p.lat, lon:utils.modulateLon(p.lng) };
};
LeafletMarker.setPosition = function(marker, opts) {
    if (typeof opts == 'undefined') opts = { };
    if (typeof opts.lat == 'undefined') opts.lat = 0;
    if (typeof opts.lon == 'undefined') opts.lon = 0;
    var position = marker.getLatLng();
    if (!position || position.lat != opts.lat || position.lng != opts.lon) {
        marker.setLatLng(new L.LatLng(opts.lat, opts.lon));
        if (marker._PW_MAP) {
            marker._PW_MAP._wrapPointItem(marker);
        }
    }
};
LeafletMarker.setVisible = function(marker, visible) {
    if (marker) {
        marker.setOpacity(visible ? 1 : 0);
    }
};

function LeafletCircleMarker(opts, circleMarker, returnOpts) {
    if (CircleMarker.isInstance(opts)) {
        [circleMarker, opts] = [opts, circleMarker];
    }
    if (circleMarker && $j.isEmptyObject(opts || {})) {
        return circleMarker;
    }

    if (typeof opts.radius == 'undefined') opts.radius = 4.5;
    if (typeof opts.lineColour == 'undefined') opts.lineColour = 'black';

    var basePath = new BasePath();
    opts = basePath.defaultOptions(opts);
    var iconOptions = {};
    if (opts.width !== undefined && opts.height !== undefined) {
        iconOptions.iconSize = [opts.width, opts.height];
    } else {
        iconOptions.iconSize = [parseInt(opts.radius*2), parseInt(opts.radius*2)];
    }
    if (opts.anchorX !== undefined && opts.anchorY !== undefined) {
        iconOptions.iconAnchor = [opts.anchorX, opts.anchorY];
    } else {
        iconOptions.iconAnchor = [parseInt(opts.radius), parseInt(opts.radius)];
    }
    iconOptions.html = "<div style='border:" + opts.lineWeight + "px solid " + opts.lineColour + "; background-color:" + opts.fillColour + "; border-radius:" + opts.radius + "px; width:" + (opts.radius * 2 - 2) + "px; height:" + (opts.radius * 2 - 2) + "px'></div>";
    opts.icon = new L.DivIcon(iconOptions);

    return new LeafletMarker(opts, circleMarker, returnOpts);
}
LeafletCircleMarker.prototype = new BaseMarker();
LeafletCircleMarker.isInstance = function(circleMarker) {
    return LeafletMarker.isInstance(circleMarker);
};


function LeafletIcon(opts, icon) {
    if (Icon.isInstance(opts)) {
        [icon, opts] = [opts, icon];
    }
    if (icon && $j.isEmptyObject(opts || {})) {
        return icon;
    }
    if (typeof opts.img == 'undefined') opts.img = icon ? icon.url : '';
    var iconOptions = {};
    if (opts.width !== undefined && opts.height !== undefined) {
        iconOptions.iconSize = [opts.width, opts.height];
    }
    if (opts.anchorX !== undefined && opts.anchorY !== undefined) {
        iconOptions.iconAnchor = [opts.anchorX, opts.anchorY];
    }
    if (opts.originX !== undefined || opts.originY !== undefined) {
        var className = utils.spriteClassName(opts.img, opts.originX, opts.originY);
        if (!hasSpriteClass[className]) {
            hasSpriteClass[className] = true;
            utils.addStyle('.' + className, 'background:transparent ' + (-(opts.originX||0)) + 'px ' + (-(opts.originY||0)) + 'px url(\'' + opts.img + '\');');
        }
        iconOptions.className = className;
        return new L.DivIcon(iconOptions);
    } else if (!opts.img && opts.className) {
        iconOptions.className = opts.className;
        return new L.DivIcon(iconOptions);
    } else {
        iconOptions.iconUrl = opts.img;
        return new L.Icon(iconOptions);
    }
}
LeafletIcon.isInstance = function(icon) {
    return icon ? (icon instanceof L.Icon) : false;
};

function LeafletElement(opts, element, returnOpts) {
    if (Element.isInstance(opts)) {
        [element, opts] = [opts, element];
    }
    if (element && $j.isEmptyObject(opts || {})) {
        return element;
    }
    if (typeof opts == 'undefined') opts = { };
    if (typeof opts.lat == 'undefined') opts.lat = element ? element._latLng.lat : 0;
    if (typeof opts.lon == 'undefined') opts.lon = element ? element._latLng.lng : 0;
    if (typeof opts.elem == 'undefined') opts.elem = element ? element._elem : document.createElement('DIV');
    if (typeof opts.hidden == 'undefined') opts.hidden = false;
    if (returnOpts) {
        $j.extend(returnOpts, opts);
    }
    var latLng = new L.LatLng(opts.lat, opts.lon);
    if (element) {
        element.setLatLng(latLng);
        element.setElement(opts.elem);
        if (element._map) {
            if (!opts.hidden) {
                element._reset();
            } else {
                element._map.removeLayer(element);
            }
        }
        return element;
    }
    return new LeafletCustomLayer(latLng, opts.elem);
}
LeafletElement.setPosition = function(element, position) {
    element.setLatLng(new L.LatLng(position.lat, position.lon));
};
LeafletElement.isInstance = function(element) {
    return element ? (element instanceof LeafletCustomLayer) : false;
};


var LeafletCustomLayer = window.L && L.Class.extend({
    initialize: function(latLng, elem) {
        // save position of the layer or any options from the constructor
        this._latLng = latLng;
        this._elem = elem;
    },

    setLatLng:function(latLng) {
        this._latLng = latLng;
        this._reset();
    },
    getLatLng:function() {
        return this._latLng;
    },
    setElement:function(elem) {
        if (elem === this._elem) {
            return;
        }
        if (this._map && this._elem) {
            this._el.removeChild(this._elem);
        }
        this._elem = elem;
        if (this._map && this._elem) {
            this._el.appendChild(this._elem);
        }
        this._reset();
    },

    onAdd: function (map) {
        this._map = map;

        // create a DOM element and put it into one of the map panes
        this._el = L.DomUtil.create('div', 'leaflet-zoom-hide leaflet-layer');
        if (this._elem) {
            this._el.appendChild(this._elem);
        }
        map.getPanes().overlayPane.appendChild(this._el);

        // add a viewreset event listener for updating layer's position, do the latter
        map.on('viewreset', this._reset, this);
        this._reset();
    },

    onRemove: function (map) {
        // remove layer's DOM elements and listeners
        map.getPanes().overlayPane.removeChild(this._el);
        map.off('viewreset', this._reset, this);
        this._map = null;
    },

    _reset: function () {
        // update layer's position
        if (this._map) {
            var pos = this._map.latLngToLayerPoint(this._latLng);
            L.DomUtil.setPosition(this._el, pos);
        }
    }
});
function LeafletLayerGroup(layers) {
    return new L.layerGroup(layers);
}
LeafletLayerGroup.isInstance = function(group){
    return group ? (group instanceof L.LayerGroup) : false;
};
LeafletLayerGroup.addItem = function(group, item) {
    group.addLayer(item);
};
LeafletLayerGroup.removeItem = function(group, item) {
    group.removeLayer(item);
};
LeafletLayerGroup.eachItem = function(group, callback) {
    group.eachLayer(callback);
};

var LeafletAttribution = window.L && L.Control.extend({
    options: {
        position: 'bottomright'
    },

    onAdd: function(map) {
        var visible = false;
        var container = L.DomUtil.create('div', 'mapLegal');

        var textDiv = L.DomUtil.create('div', 'mapLegalInfo');
        textDiv.style.display = 'none';

        utils.addStyle('.mapLegal', 'margin-left:10px;');
        utils.addStyle('.mapLegal a', 'text-decoration:none;');
        utils.addStyle('.mapLegalButton', 'float:right;border:0px;margin:0px;background-color:white;font-size:10px;box-shadow:0 1px 5px rgba(0,0,0,0.65);border-radius:4px;');
        utils.addStyle('.mapLegalInfo', 'padding:0 5px;margin-bottom:6px;background-color:white;font-size:11px;box-shadow:rgba(0, 0, 0, 0.65098) 0px 1px 5px;');
        utils.addStyle('.tvf .expand', 'right:10px;bottom:31px;');

        var toggle = L.DomUtil.create('button', 'mapLegalButton');
        toggle.innerHTML = 'Legal Info';
        textDiv.onclick =
        toggle.onclick = function() {
            visible = !visible;
            if (visible) {
                textDiv.style.display = 'block';
            } else {
                textDiv.style.display = 'none';
            }
        };

        container.appendChild(textDiv);
        container.appendChild(toggle);

        var attributions = [];
        function updateContainer() {
            var html = '';
            for (var i = 0; i < attributions.length; i++) {
                html += attributions[i] + '<br>';
            }
            textDiv.innerHTML = html;
        }

        map.eachLayer(function(layer){
            if (layer.getAttribution) {
                attributions.push(layer.getAttribution());
                updateContainer();
            }
        });
        map.on('layeradd', function(e){
            if (e.layer.getAttribution) {
                attributions.push(e.layer.getAttribution());
                updateContainer();
            }
        });
        return container;
    }
});


function AtlasMap() {
    BaseMap.call(this);
    this._atlasMap = null;
    this._popup = null;
    this._popupHtml = null;
    this._popupFooter = null;
}

AtlasMap.prototype = new BaseMap();
AtlasMap.prototype.constructor = AtlasMap;
AtlasMap.prototype.isValid = function(){
    return (this._atlasMap !== null);
};

AtlasMap.prototype.initialise = function(element, opts) {
    var that = this;
    opts = this.defaultOptions(opts);
    var options = {
        lat: opts.lat,
        lon: opts.lon,
        zoom: opts.zoom,
        minZoom: opts.minZoom,
        maxZoom: opts.maxZoom,
        maxBounds: opts.maxBounds,
        zoomControl: opts.zoomControl,
        keyboardShortcuts: opts.keyboardShortcuts,
        element: element
    };
    if (pwMapDefaultTMS === 'mapbox') {
        options.tileSize = mapBoxAtlasTileSize;
    }

    this._atlasMap = new window.Atlas.Map(options);

    if (opts.baseLayers) {
        for (var i = 0; i < opts.baseLayers.length; i++) {
            this._atlasMap.add(opts.baseLayers[i]);
        }
        if (typeof opts.baseLayerIndex !== 'undefined' && opts.baseLayerIndex >= 0) {
            this._baseLayer = opts.baseLayers[opts.baseLayerIndex];
        }
    }
    if (!this._baseLayer) {
        this._baseLayer = new window.Atlas.ItemLayer();
        this._atlasMap.add(this._baseLayer);

        var mapTileUrl = ((window.app && app.client.settings.appName == 'Offshore') ?
            getSetting('Maps_TileOverrideUrl', getDefaultTileLayerUrl())
            : getDefaultTileLayerUrl()
        );
        var mapTileLayer = new window.Atlas.TileLayer({
            renderInMapLayer:true,
            zIndex:1000,
            tileSources:[
                { type:'image', url:mapTileUrl, zoomSubset:[3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15], zoomOffset:0, maxZoom:16 }
            ]
        });
        var vectorTileUrl = ((window.app && app.client.settings.appName == 'Offshore') ?
            getSetting('Maps_VectorTileOverrideUrl', getDefaultVectorTileLayerUrl())
            : getDefaultVectorTileLayerUrl()
        );
        var vectorTileLayer = new window.Atlas.TileLayer({
            renderInMapLayer:true,
            zIndex:1000,
            tileSources:[
                { type:'vector', url:vectorTileUrl }
            ]
        });
        var gribLayer = new window.Atlas.TileLayer({
            tileSources:[{ type:'grib', url:'/atlas/gribtile/{gribKey}-{subImage}.raw', layerName:'HighResolution', zoomSubset:[0] }]
        });

        this._atlasMap.add(mapTileLayer);
        this._atlasMap.add(vectorTileLayer);
        this._atlasMap.add(gribLayer);
    }
    this._itemLayer = new window.Atlas.ItemLayer();
    this._atlasMap.add(this._itemLayer);

    // Handle some mouse events. Hacky use of Atlas internals. Not properly tested.
    function mouseEvent(e) {
        if (e) {
            var i, oe = e, keys = ["shiftKey","ctrlKey","altKey","metaKey"], n=keys.length;
            var pointer = that._atlasMap._eventMousePointers(e, {})[0];
            var coord = that._atlasMap.projection.linearFromPoint(pointer);
            e = {latlon: window.Atlas.inverseMercator(coord)};
            for (i=0; i<n; ++i) {
                e[keys[i]] = oe[keys[i]];
            }
        }
        return e;
    }

    this._atlasMap.addListener('click', function (e) {that.clickHandler.fire({lat: e.lat, lon: e.lon})});

    function makeHook(callback, optDelayCheck) {
        var timeoutHandle = null;
        function hook() {
            if (!callback || timeoutHandle !== null) {
                return;
            }
            timeoutHandle = setTimeout(serviceHook, 0);
        }
        function serviceHook() {
            if (optDelayCheck && optDelayCheck()) {
                timeoutHandle = setTimeout(serviceHook, 10);
            } else {
                timeoutHandle = null;
                callback();
            }
        }
        return hook;
    }
    var zoomChangedHook = makeHook(
        function() {
            that.zoomChanged.fire();
        },
        function() {
            return that._atlasMap && that._atlasMap._isZooming();
        }
    );
    var viewportHook = makeHook(
        function() {
            that.viewportHandler.fire();
        }
    );
    function zoomHook() {
        viewportHook();
        zoomChangedHook();
    }
    window.Atlas.properties(this._atlasMap, {
        hook_lat:viewportHook,
        hook_lon:viewportHook,
        hook_zoom:zoomHook,
    });

    $j(this._atlasMap.renderer.domElement).on('mouseout', function(e) {
        that.mouseOut.fire(mouseEvent(e));
    }).on('mouseover', function(e){
        that.mouseOver.fire(mouseEvent(e));
    });
};
AtlasMap.prototype.addBase = function(item) {
    if (this._baseLayer) {
        this._baseLayer.add(item);
    }
};
AtlasMap.prototype.containsBase = function(item) {
    if (this._baseLayer) {
        this._baseLayer.contains(item);
    }
};
AtlasMap.prototype.removeBase = function(item) {
    if (this._baseLayer) {
        this._baseLayer.remove(item);
    }
};
AtlasMap.prototype.addItem = function(item) {
    if (this._itemLayer) {
        this._itemLayer.add(item);
    }
};
AtlasMap.prototype.containsItem = function(item) {
    if (this._itemLayer) {
        return this._itemLayer.contains(item);
    }
};
AtlasMap.prototype.removeItem = function(item) {
    if (this._itemLayer) {
        this._itemLayer.remove(item);
    }
};
AtlasMap.prototype.openPopup = function(point, html, optFooter, messageStyle) {
    var that = this;
    var needsOpen = false;
    if (!this._infoWindow) {
        this._infoWindowContent = $j('<div>')[0];
        this._infoWindow = new window.Atlas.Element({
            element:window.Atlas.createBalloon(this._infoWindowContent, function(){
                        that.closePopup(true);
                    })
        });
        needsOpen = true;
    }
    if (this._infoWindowHtml != html || optFooter !== this._infoWindowFooter) {
        this._infoWindowHtml = html;
        this._infoWindowFooter = optFooter;
        this._infoWindowContent.innerHTML = html;
        if (optFooter) {
            $j(this._infoWindowContent).append($j.parseHTML(optFooter));
        }
    }
    if (messageStyle) {
        $j(this._infoWindow.element).find('.atlas.message').css(messageStyle);
    }
    this._infoWindow.lat = point.lat;
    this._infoWindow.lon = utils.modulateLon(point.lon);
    this._infoWindowClosedManually = false;
    if (needsOpen && this._atlasMap) {
        this._itemLayer.add(this._infoWindow);
    }
    return this._infoWindowContent;
};
AtlasMap.prototype.closePopup = function(closedManually) {
    if (this._infoWindow) {
        this._itemLayer.remove(this._infoWindow);
        this._infoWindow = null;
        this._infoWindowContent = null;
        this._infoWindowHtml = null;
        this._infoWindowFooter = null;
        this._infoWindowClosedManually = closedManually;
    }
};
AtlasMap.prototype.hasPopup = function() {
    return !!this._infoWindow;
};
AtlasMap.prototype.wasPopupClosedManually = function() {
    return this._infoWindowClosedManually || false;
};
AtlasMap.prototype.setDisplayTime = function(time) {
    this._atlasMap.displayTime = time;
};
AtlasMap.prototype.getCentre = function() {
    var atlasMap = this._atlasMap;
    if (atlasMap) {
        return {lat:atlasMap.lat, lon:atlasMap.lon};
    }
    return null;
};
AtlasMap.prototype.setCentre = function(centre) {
    var atlasMap = this._atlasMap;
    if (atlasMap) {
        atlasMap.lon = centre.lon;
        atlasMap.lat = centre.lat;
    }
};
AtlasMap.prototype.getZoom = function() {
    var atlasMap = this._atlasMap;
    if (atlasMap) {
        return atlasMap.zoom;
    }
    return null;
};
AtlasMap.prototype.setZoom = function(zoom) {
    var atlasMap = this._atlasMap;
    if (atlasMap) {
        atlasMap.zoom = zoom;
    }
};
AtlasMap.prototype.getBounds = function() {
    var atlasMap = this._atlasMap;
    if (atlasMap) {
        atlasMap.projection.updateCamera(atlasMap);
        return atlasMap.projection.bounds;
    }
    return null;
};
AtlasMap.prototype.setBounds = function(bounds) {
    var atlasMap = this._atlasMap;
    if (atlasMap) {
        var modulate = utils.modulateLon;
        var b = {
            n: bounds.n,
            s: bounds.s,
            e: modulate(bounds.e),
            w: modulate(bounds.w)
        };
        var y0 = window.Atlas.mercator(b.s, 0).y;
        var y1 = window.Atlas.mercator(b.n, 0).y;
        var lat = window.Atlas.inverseMercator(0, (y1+y0)/2).lat;
        var lon = modulate((b.w + b.e)/2 + (b.w > b.e ? 180 : 0));
        var deltaX = ((b.e - b.w) + (b.w > b.e ? 360 : 0)) / 180;
        var xScale = deltaX * 256 / atlasMap.projection.viewport.width;
        var yScale = (y1-y0) * 256 / atlasMap.projection.viewport.height;
        this.setZoom(Math.floor(-Math.log(Math.max(xScale,yScale))/Math.LN2));
        this.setCentre({lat:lat, lon:lon});
    }
};

function AtlasPolyline(opts, opts2) {
    BasePath.apply(this, arguments);
    var polyline = null;
    if (opts && opts instanceof window.Atlas.Polyline) {
        polyline = opts;
        if (typeof opts2 == 'undefined') {
            return polyline;
        } else {
            opts = opts2;
        }
    } else {
        opts = this.defaultOptions(opts);
    }
    if (polyline) {
        $j.extend(polyline, opts);
    } else {
        return new window.Atlas.Polyline(opts);
    }
}
AtlasPolyline.prototype = new BasePath();
AtlasPolyline.setPath = function(polyline, newPath) {
    var path = [];
    for (var i = 0; i < newPath.length; i++) {
        path[i] = { lat:newPath[i].lat, lon:utils.modulateLon(newPath[i].lon) };
    }
    polyline.path = path;
    polyline.updatePath();
};

function AtlasPolygon(opts, opts2){
    BasePath.apply(this, arguments);
    var polygon = null;
    if (opts && opts instanceof window.Atlas.Polygon) {
        polygon = opts;
        if (typeof opts2 == 'undefined') {
            return polygon;
        } else {
            opts = opts2;
        }
    } else {
        opts = this.defaultOptions(opts);
    }
    if (polygon) {
        $j.extend(polygon, opts);
    } else {
        return new window.Atlas.Polygon(opts);
    }
}
AtlasPolygon.prototype = new BasePath();
AtlasPolygon.setPath = function(polygon, newPath) {
    polygon.path = newPath.slice();
    polygon.updatePath();
};


function AtlasRectangle(opts, opts2) {
    BaseRectangle.apply(this, arguments);
    var rectangle = null;
    if (opts && opts instanceof window.Atlas.Rectangle) {
        rectangle = opts;
        if (typeof opts2 == 'undefined') {
            return rectangle;
        } else {
            opts = opts2;
        }
    } else {
        opts = this.defaultOptions(opts);
    }
    while (opts.e < opts.w) {
        opts.e += 360;
    }
    if (opts.colour !== undefined) {
        var outlineOnly = opts.outlineOnly !== undefined ? opts.outlineOnly : rectangle && rectangle.fillOpacity === 0.0;
        if (outlineOnly) {
            opts.lineColour = opts.colour;
            opts.fillOpacity = 0.0;
        } else {
            opts.fillColour =  opts.colour;
        }
        delete opts.colour;
    }

    var options = {};
    if (opts.n !== undefined) options.n = opts.n;
    if (opts.s !== undefined) options.s = opts.s;
    if (opts.e !== undefined) options.e = opts.e;
    if (opts.w !== undefined) options.w = opts.w;
    if (opts.opacity !== undefined) options.lineOpacity = opts.opacity;
    if (opts.opacity !== undefined) options.fillOpacity = opts.opacity;
    if (opts.lineColour !== undefined) options.lineColour = opts.lineColour;
    if (opts.lineOpacity !== undefined) options.lineOpacity = opts.lineOpacity;
    if (opts.lineWeight !== undefined) options.lineWeight = opts.lineWeight;
    if (opts.fillColour !== undefined) options.fillColour = opts.fillColour;
    if (opts.fillOpacity !== undefined) options.fillOpacity = opts.fillOpacity;
    if (opts.hidden !== undefined) options.visible = !opts.hidden;
    if (opts.click !== undefined) options.click = opts.click;
    if (opts.zIndex !== undefined) options.zIndex = opts.zIndex;

    if (rectangle) {
        $j.extend(rectangle, options);
    } else {
        rectangle = new window.Atlas.Rectangle(options);
    }
    return rectangle;
}
AtlasRectangle.prototype = new BaseRectangle();
AtlasRectangle.setBounds = function(rect, b) {
    rect.n = b.n;
    rect.s = b.s;
    rect.e = b.e;
    rect.w = b.w;
};


function AtlasCircle(opts, opts2){
    BasePath.apply(this, arguments);
    var circle = null;
    if (opts && opts instanceof window.Atlas.Circle) {
        circle = opts;
        if (typeof opts2 == 'undefined') {
            return circle;
        } else {
            opts = opts2;
        }
    }
    opts = this.defaultOptions(opts);

    var circleOptions = {
        lat: opts.centre.lat,
        lon: opts.centre.lon,
        radius: opts.radius,
        lineColour: opts.lineColour,
        lineOpacity: opts.lineOpacity,
        lineWeight: opts.lineWeight,
        fillColour: opts.fillColour,
        fillOpacity: opts.fillOpacity
    };

    if (circle) {
        $j.extend(circle, circleOptions);
    } else {
        return new window.Atlas.Circle(circleOptions);
    }
}
AtlasCircle.prototype = Object.create(BasePath.prototype);

function AtlasCircleMarker(opts, circleMarker, returnOpts) {
    if (CircleMarker.isInstance(opts)) {
        [circleMarker, opts] = [opts, circleMarker];
    }
    if (circleMarker && $j.isEmptyObject(opts || {})) {
        return circleMarker;
    }

    if (typeof opts.radius === 'undefined') opts.radius = 8;
    if (typeof opts.fillColour === 'undefined') opts.fillColour = 'white';
    if (typeof opts.fillOpacity === 'undefined') opts.fillOpacity = 1;
    if (typeof opts.lineColour === 'undefined') opts.lineColour = 'black';
    if (typeof opts.lineOpacity === 'undefined') opts.lineOpacity = 1;
    if (typeof opts.lineWeight === 'undefined') opts.lineWeight = 0.75;

    var basePath = new BasePath();
    opts = basePath.defaultOptions(opts);
    opts.icon = window.Atlas.createCircleIcon(opts);
    return new AtlasMarker(opts, circleMarker, returnOpts);
}
AtlasCircleMarker.prototype = new BaseMarker();
AtlasCircleMarker.isInstance = function(circleMarker) {
    return AtlasMarker.isInstance(circleMarker);
};

function AtlasMarker(opts, marker, returnOpts) {
    if (Marker.isInstance(opts)) {
        [marker, opts] = [opts, marker];
    }
    if (marker && $j.isEmptyObject(opts || {})) {
        return marker;
    }
    opts = $j.extend({}, opts);
    if (!opts.icon) {
        opts.icon = icon(opts, marker ? marker._PW_ICON : undefined);
    }
    BaseMarker.apply(this, [opts, marker]);
    opts = this.defaultOptions(opts);
    if (returnOpts) {
        $j.extend(returnOpts, opts);
    }
    var markerOptions = {
        icon: opts.icon,
        lat:opts.position.lat,
        lon:opts.position.lon,
        rotation: opts.rotation || 0,
        click:opts.click,
        drag:opts.drag,
        dragged:opts.dragged,
        clickable: opts.clickable || opts.draggable,
        draggable: opts.draggable,
        width: opts.width ?? null,
        height: opts.height ?? null,
        anchorX: opts.anchorX ?? null,
        anchorY: opts.anchorY ?? null,
        zIndex: opts.zIndex
    };
    if (opts.tag) {
        markerOptions.tag = opts.tag;
    }
    if (marker) {
        AtlasMarker.setPosition(marker, opts.position);
        AtlasMarker.setIcon(marker, opts.icon);
    } else {
        marker = new window.Atlas.Marker(markerOptions);
        marker._PW_ICON = markerOptions.icon;
    }
    var click = opts.click;
    if (click) {
        marker.clickHandler.add(function(e){ click.call(marker, { lat:marker.lat, lon:marker.lon }); });
        marker.clickable = true;
    }
    var drag = opts.drag;
    if (drag) {
        marker.dragHandler.add(function(e){ drag.call(marker, { lat:marker.lat, lon:marker.lon }); });
        marker.draggable = true;
    }
    var dragged = opts.dragged;
    if (dragged) {
        marker.draggedHandler.add(function(e){ dragged.call(marker, { lat:marker.lat, lon:marker.lon }); });
        marker.draggable = true;
    }
    return marker;
}
AtlasMarker.prototype = new BaseMarker();
AtlasMarker.isInstance = function(marker) {
    return marker ? (marker instanceof window.Atlas.Marker) : false;
};
AtlasMarker.getIcon = function(marker) {
    return marker ? marker._PW_ICON : null;
};
AtlasMarker.setIcon = function(marker, opts) {
    if (!marker || !opts) return;
    if (marker._PW_ICON !== opts) {
        let _icon = icon(opts);
        marker.icon = _icon;
        marker._PW_ICON = _icon;
        if (marker._markerClone) {
            marker._markerClone.icon = _icon;
        }
    }
};
AtlasMarker.getPosition = function(marker) {
    return { lat:marker.lat, lon:utils.modulateLon(marker.lon) };
};
AtlasMarker.setPosition = function(marker, opts) {
    if (!marker || !opts) return;
    if (typeof opts == 'undefined') opts = { };
    if (typeof opts.lat == 'undefined') opts.lat = 0;
    if (typeof opts.lon == 'undefined') opts.lon = 0;
    marker.lat = opts.lat;
    marker.lon = opts.lon;
};
AtlasMarker.setVisible = function(marker, visible) {
    if (!marker) return;
    marker.visible = !!visible;
};



function AtlasIcon(opts, icon) {
    if (Icon.isInstance(opts)) {
        [icon, opts] = [opts, icon];
    }
    if (icon && $j.isEmptyObject(opts || {})) {
        return icon;
    }
    if (typeof opts.img == 'undefined') opts.img = icon ? icon.url : '';
    if (!opts.img && opts.className) {
        // In Leaflet, we just make a DivIcon using the className.
        // Here we need to turn the background image for the className into an img.
        var d = $j('<div>').addClass('atlas icon ' + opts.className).appendTo(document.body);
        // http://stackoverflow.com/questions/8809876/can-i-get-divs-background-image-url
        var bgUrl = d.css('background-image');

        // ^ Either "none" or url("...urlhere..")
        bgUrl = /^url\((['"]?)(.*)\1\)$/.exec(bgUrl);
        bgUrl = bgUrl ? bgUrl[2] : ""; // If matched, retrieve url, otherwise ""
        opts.img = bgUrl;
        if (bgUrl) {
            // If the CSS has explicitly specified a height and width in px, use that instead of width and height options.
            var bgSize = d.css('background-size');
            bgSize = /^(\d+(?:\.\d+)?)px (\d+(?:\.\d+)?)px$/.exec(bgSize);
            if (bgSize) {
                opts.width = parseFloat(bgSize[1]);
                opts.height = parseFloat(bgSize[2]);
            }
        }
        d.remove();
    }

    // opts is already correct, except img => url
    opts = $j.extend({}, opts || {});
    opts.url = opts.img;
    delete opts.img;

    // TODO: For now we assume icons are not modified
    return new window.Atlas.Icon(opts);
}
AtlasIcon.isInstance = function(icon) {
    return icon ? (icon instanceof window.Atlas.Icon) : false;
};

function AtlasElement(opts, element){
    if (Element.isInstance(opts)) {
        [element, opts] = [opts, element];
    }
    if (element && $j.isEmptyObject(opts || {})) {
        return element;
    }
    if (typeof opts == 'undefined') opts = { };
    if (typeof opts.lat == 'undefined') opts.lat = element? element.lat : 0;
    if (typeof opts.lon == 'undefined') opts.lon = element? element.lon : 0;
    if (typeof opts.elem == 'undefined') opts.elem = element? element.element : document.createElement('DIV');
    if (typeof opts.hidden == 'undefined') opts.hidden = false;
    var options = {
        lat:opts.lat,
        lon:opts.lon,
        element:opts.elem,
        visible:!opts.hidden
    };
    if (typeof opts.centred !== 'undefined') options.centred = !!opts.centred;
    if (typeof opts.centredX !== 'undefined') options.centredX = !!opts.centredX;
    if (typeof opts.centredY !== 'undefined') options.centredY = !!opts.centredY;
    if (opts.tag) {
        options.tag = opts.tag;
    }
    if (element) {
        $j.extend(element, options);
    } else {
        return new window.Atlas.Element(options);
    }
}
AtlasElement.isInstance = function(element) {
    return element ? (element instanceof window.Atlas.Element) : false;
};
AtlasElement.setPosition = function(element, opts) {
    element.lat = opts.lat * 1;
    element.lon = opts.lon * 1;
};


function AtlasLayerGroup(items) {
    var group = new window.Atlas.Group();
    if (items) {
        for (var i = 0; i < items.length; i++) {
            group.add(items[i]);
        }
    }
    return group;
}
AtlasLayerGroup.isInstance = function(group){
    return group ? (group instanceof window.Atlas.Group) : false;
};
AtlasLayerGroup.addItem = function(group, item) {
    group.add(item);
};
AtlasLayerGroup.removeItem = function(group, item) {
    group.remove(item);
};
AtlasLayerGroup.eachItem = function(group, callback) {
    group.each(callback);
};


export function Grib() {
    LayerGroup.apply(this, []);
    this.data = {
        width:0,
        height:0,
        affine:[0, 1, 0,  // xo, xx, xy
                0, 0, 1], // yo, yx, yy
        channels:{}
    };
    this.display = 'wind';
}
Grib.prototype = new LayerGroup();
Grib.isInstance = function(grib) {
    return grib ? LayerGroup.isInstance(grib) : false;
};
Grib.prototype.setDataDisplay = function(data, display) {
    if (this.data === data && this.display === display) {
        return;
    }
    this.data = data;
    this.display = display;
    this.rebuild();
};
Grib.prototype.clear = function() {
    var that = this;
    LayerGroup.eachItem(this, function(item){
        LayerGroup.removeItem(that, item);
        if (item.dispose) {
            item.dispose();
        }
    });
};
Grib.prototype.rebuild = function() {
    if (this.display == 'wind') {
        this.displayWind();
    }
};
Grib.prototype.displayWind = function() {
    this.clear();
    var tws = this.data.channels.tws;
    var twd = this.data.channels.twd;
    if (!tws || !twd) {
        return;
    }

    // TODO: break data into zoom levels to reduce load and rendering time
    var step = 1;  // stand-in for zoom levels

    // TODO: select data by current time
    var twdData = twd[0].d;

    var xo = this.data.affine[0], xx = this.data.affine[1], xy = this.data.affine[2];
    var yo = this.data.affine[3], yx = this.data.affine[4], yy = this.data.affine[5];
    xx *= step; xy *= step; yx *= step; yy *= step;
    for (var j = 0, latY = yo, lonY = xo; j < this.data.height; j += step, latY += yy, lonY += xy) {
        for (var i = 0, lat = latY, lon = lonY; i < this.data.width; i += step, lon += xx, lat += yx) {
            var marker = marker({ position:{ lat:lat, lon:lon } });
            marker._plane.rotation.set(0, 0, twdData[i + j * this.data.width] / 180 * Math.PI);
            LayerGroup.addItem(this, marker);
        }
    }
};

function getQueryParam(name, defaultValue) {
    name = name.replace(/[\[]/,"\\\[").replace(/[\]]/,"\\\]");
    var regexS = "[\\?&]" + name + "=([^&#]*)";
    var regex = new RegExp(regexS);
    var results = regex.exec(window.location.href);
    if (results == null) {
        return defaultValue;
    } else {
        return decodeURI(results[1]) || defaultValue;
    }
}

function NominatimGeocoder() {
    this.search = function(searchText, callback){
        var baseUrl = 'http://nominatim.openstreetmap.org/search?format=json&q=';
        var url = baseUrl + encodeURIComponent(searchText);
        $j.get(url, function(nominatimResults) {
            var results = null;
            if (nominatimResults && nominatimResults.length) {
                results = [];
                for (var i = 0; i < nominatimResults.length; i++) {
                    var nominatimResult = nominatimResults[i];
                    var result = {
                        b:{
                            s:nominatimResult.boundingbox[0] * 1,
                            n:nominatimResult.boundingbox[1] * 1,
                            w:nominatimResult.boundingbox[2] * 1,
                            e:nominatimResult.boundingbox[3] * 1
                        },
                        lat:nominatimResult.lat * 1,
                        lon:nominatimResult.lon * 1,
                        zoom:SEARCH_ZOOM_DEFAULT
                    };
                    results.push(result);
                }
            }
            callback(results);
        }, 'json');
    };
}

utils.addStyle('.leaflet-objects-pane .leaflet-div-icon', 'background:transparent; border:none;');

if (!window.PW_formatWaypoint) window.PW_formatWaypoint = utils.formatCoords;
